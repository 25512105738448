import React, { useState } from 'react';
import rewardsMapper from '../utils/rewardsMapper';

import './farms-list.scss';

// import {
//   // CONFIGURE_RETURNED,
//   GET_BALANCES,
//   GET_BALANCES_RETURNED,
//   GET_BOOSTEDBALANCES_RETURNED,
//   GET_BOOSTEDBALANCES,
// } from '../../constants/constants';

import Store from '../../stores/store';
import Farm from './farm';

const { store } = Store;

const FarmsList = (props) => {
  const [farmPools] = useState(store.getStore('farmPools'));
  // const [account, _] = useState(store.getStore('account'));
  // const [loading, setLoading] = useState(!(account && farmPools));

  // const configureReturned = useCallback(() => setLoading(false));

  const farms = rewardsMapper(farmPools).map((t) => {
    return (
      <div key={t.address} className='col-lg-3 col-md-4 col-sm-6'>
        <Farm
          data={t}
          acronym={t.symbol}
          name={t.name}
          address={t.address}
          inPool={t.inPool}
          beastBonus={t.beastBonus || 0}
          bonusReductionIn={t.bonusReductionIn || 0}
          weeklyRewards={t.weeklyRewards}
          myBeastModes={t.myBeastModes}
          myRewards={t.myRewards}
          symbol={t.symbol}
          // ratePerWeek={t.ratePerWeek}
          rewardsSymbol={t.rewardsSymbol}
          stakedBalance={t.stakedBalance}
          costBooster={t.costBooster}
          costBoosterUSD={t.costBoosterUSD}
          timeToNextBoost={t.timeToNextBoost}
          currentBoosterStakeValue={t.currentBoosterStakeValue}
          stakeValueNextBooster={t.stakeValueNextBooster}
          liquidityLink={t.liquidityLink}
          tokenAddress={t.tokenAddress}
          tokenSymbol={t.tokenSymbol}
          ethPrice={t.ethPrice}
          boostBalance={t.boostBalance}
          disableStake={t.disableStake}
          token={t.token}
        />
      </div>
    );
  });

  return (
    <div
      className='row farms-wrapper'
      style={{
        justifyContent: props.justifyContent ? props.justifyContent : 'center',
      }}
    >
      {farms}
    </div>
  );
};

export default FarmsList;
