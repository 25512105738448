import React, { Component } from 'react';

import { Switch, Route } from 'react-router-dom';
import IpfsRouter from 'ipfs-react-router';
import Leftnav from './components/leftnav/leftnav';
import Header from './components/header/header';
import Exchange from './components/exchange/exchange';
import Launchpad from './components/launchpad/launchpad';
import ScrollToTop from './components/navigation/scrolltotop';
import SnackBar from './components/utils/snackbar';
import Rewards from './components/adm/rewards';
import Boosted from './components/adm/boosted';

import './i18n';

import Account from './components/account/account';
import Home from './components/home/home';
import Farms from './components/farms/farms';
import Hives from './components/hives/hives';
import WhaleTank from './components/whalestank/whaletank';
import Stake from './components/stake/stake';
import Swarm from './components/swarm/swarm';
import Pools from './components/pools/pools';
import NetworkAlert from './components/networkAlert/networkAlert';

import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONFIGURE,
  CONFIGURE_RETURNED,
  GET_BALANCES_RETURNED,
  GET_BALANCES,
  ERROR,
} from './constants';

import { injected } from './stores/connectors';

import Store from './stores/store';
const { emitter, dispatcher, store } = Store;

class App extends Component {
  state = {
    account: null,
    headerValue: null,
    themeType: false,
    style: 'dark-mode',
    initApp: 0,
    snackBarOpen: false,
    snackBarSeverity: 'success',
    snackBarMessage: '',
  };

  setHeaderValue = (newValue) => {
    this.setState({ headerValue: newValue });
  };

  componentDidMount() {
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.on(CONFIGURE_RETURNED, this.configureReturned);
    emitter.on(GET_BALANCES_RETURNED, this.getBalancesReturned);

    emitter.on(ERROR, this.errorHandler);

    let style = localStorage.getItem('theme');
    if (style) this.setState({ style });

    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        injected
          .activate()
          .then((a) => {
            store.setStore({
              account: { address: a.account },
              web3context: { library: { provider: a.provider } },
            });
            emitter.emit(CONNECTION_CONNECTED);
            // console.info(a);
          })
          .catch((e) => {
            console.info(e);
          });
      } else {
      }
    });

    const isCacheCleared = JSON.parse(localStorage.getItem('cleared'));

    //clear cache
    const clearCache = () => {
      if (!isCacheCleared) {
        caches
          .keys()
          .then((names) => {
            console.log(names);
            names.forEach((name) => {
              caches.delete(name);
            });
          })
          .catch((err) => {
            console.log(err);
          });
        //Cache Cleared
        localStorage.setItem('cleared', true);
      }
    };
    clearCache();
  }

  componentWillUnmount() {
    emitter.removeAllListeners();
  }

  getBalancesReturned = () => {
    window.setTimeout(() => {
      dispatcher.dispatch({ type: GET_BALANCES, content: {} });
    }, 300000);
  };

  errorHandler = (error) => {
    let message = 'An error has occurred';
    if (error?.code === -32000) return;
    if (typeof error === 'string') message = error;
    if (typeof error === 'object' && error.message) message = error.message;
    console.info(error);
    this.setSnackBar(true, 'warning', message);
  };

  configureReturned = async () => {
    await store.getBalances();
    //await store.getExchangeTokensPrice();
    await store.getBalancesFarms();
    await store.getBoostBalances();
    await store.getBoostBalancesFarms();
    await store.getLpBalances();
    // window.setTimeout(() => {
    //   emitter.emit(GET_ALL_BALANCES_RETURNED);
    // }, 1000);
    this.setState({ initApp: 1 });

    // dispatcher.dispatch({ type: GET_BALANCES, content: {} });
  };

  connectionConnected = () => {
    // console.info('connection connected');
    this.setState({ account: store.getStore('account') });
    dispatcher.dispatch({ type: CONFIGURE, content: {} });
  };

  connectionDisconnected = () => {
    // console.info(store.getStore('account'));
    this.setState({ account: store.getStore('account') });
  };

  setSnackBar = (open, severity = 'info', message = '') => {
    this.setState({
      snackBarOpen: open,
      snackBarSeverity: severity,
      snackBarMessage: message,
    });
  };

  onSwitchThemeHandler = () => {
    let style = this.state.style === 'light-mode' ? 'dark-mode' : 'light-mode';
    localStorage.setItem('theme', style);
    this.setState({ style });
  };

  render() {
    const {
      account,
      style,
      initApp,
      snackBarMessage,
      snackBarOpen,
      snackBarSeverity,
    } = this.state;
    // console.info(store.getStore('account'));

    return (
      <div className={`${style} main-content`}>
        <SnackBar
          open={snackBarOpen}
          setOpen={this.setSnackBar}
          message={snackBarMessage}
          severity={snackBarSeverity}
        />
        <IpfsRouter>
          <ScrollToTop />
          <Header />
          <Leftnav
            onSwitchTheme={this.onSwitchThemeHandler}
            activeStyle={this.state.style}
          />
          {!account || !account.address || !initApp ? (
            <Account />
          ) : (
            <>
              <NetworkAlert />
              <Switch>
                <Route path='/stake/:address' component={Stake} />
                <Route path='/whaletank' component={WhaleTank} />
                <Route path='/hives' component={Hives} />
                <Route path='/launchpad' component={Launchpad} />
                <Route path='/farms' component={Farms} />
                {/* <Route path='/exchange' component={Exchange} /> */}
                <Route exact path='/pools/:selectedPool'>
                  <Pools
                    assetsStoreKey='exchangeAssets'
                    extraAssets={['ETH', 'WPE']}
                    disableSwap={true}
                  />
                </Route>
                <Route path='/pools'>
                  <Pools
                    assetsStoreKey='exchangeAssets'
                    extraAssets={['ETH', 'WPE']}
                    disableSwap={true}
                  />
                </Route>
                <Route path='/swarm' component={Swarm} />
                <Route path='/adm/do-rewards' component={Rewards} />
                <Route path='/adm/boosted' component={Boosted} />
                <Route path='/' component={Home} />
              </Switch>
            </>
          )}
        </IpfsRouter>
      </div>
    );
  }
}

export default App;
