import React, { useState } from 'react';
import rewardsMapper from '../utils/rewardsMapper';

import './hives-list.scss';

import Store from '../../stores/store';
import Hive from './hive';

const { store } = Store;

const HivesList = ({ justifyContent }) => {
  const [rewardPools] = useState(
    store.getStore('rewardPools').filter(({ isNormalHive }) => isNormalHive)
  );

  const hives = rewardsMapper(rewardPools).map((t) => {
    return (
      <div className='col-lg-3 col-md-4 col-sm-6' key={t.address}>
        <Hive
          data={t}
          // acronym={t.symbol}
          // name={t.name}
          address={t.address}
          // inPool={t.inPool}
          beastBonus={t.beastBonus || 0}
          // bonusReductionIn={t.bonusReductionIn || 0}
          weeklyRewards={t.weeklyRewards}
          myBeastModes={t.myBeastModes}
          myRewards={t.myRewards}
          symbol={t.symbol}
          // ratePerWeek={t.ratePerWeek}
          rewardsSymbol={t.rewardsSymbol}
          // stakedBalance={t.stakedBalance}
          costBooster={t.costBooster}
          costBoosterUSD={t.costBoosterUSD}
          timeToNextBoost={t.timeToNextBoost}
          currentBoosterStakeValue={t.currentBoosterStakeValue}
          stakeValueNextBooster={t.stakeValueNextBooster}
          liquidityLink={t.liquidityLink}
          tokenAddress={t.tokenAddress}
          tokenSymbol={t.tokenSymbol}
          ethPrice={t.ethPrice}
          boostBalance={t.boostBalance}
          disableStake={t.disableStake}
          token={t.token}
        />
      </div>
    );
  });

  return (
    <div
      className='row hives-wrapper'
      style={{
        justifyContent: justifyContent ? justifyContent : 'center',
      }}
    >
      {hives.reverse()}
    </div>
  );
};

export default HivesList;
