import config from '../config/config';
import async from 'async';
import {
  ERROR,
  CONFIGURE,
  CONFIGURE_RETURNED,
  GET_BALANCES,
  GET_BALANCES_RETURNED,
  GET_BALANCES_PERPETUAL,
  GET_BALANCES_PERPETUAL_RETURNED,
  STAKE,
  STAKE_RETURNED,
  WITHDRAW,
  WITHDRAW_RETURNED,
  GET_REWARDS,
  GET_REWARDS_RETURNED,
  EXIT,
  EXIT_RETURNED,
  GET_CLAIMABLE_ASSET,
  GET_CLAIMABLE_ASSET_RETURNED,
  CLAIM,
  CLAIM_RETURNED,
  GET_CLAIMABLE,
  GET_CLAIMABLE_RETURNED,
  GET_BOOSTEDBALANCES,
  GET_BOOSTEDBALANCES_RETURNED,
  BOOST_STAKE,
  EXCHANGE,
  EXCHANGE_RETURNED,
  BUY_LP,
  BUY_LP_RETURNED,
  GET_ALL_BALANCES_RETURNED,
} from '../constants';
import Web3 from 'web3';
import STORE_INIT_CONSTANTS from './store-init-constant';
import * as balancesLib from './lib';
import { MemoFetch } from './lib/utils';
// import { useMemo } from 'react';
// import * as balanceHivesFns from './balance.hives.functions';

const rp = require('request-promise');

const { Dispatcher } = require('flux');
const Emitter = require('events').EventEmitter;

const dispatcher = new Dispatcher();
const emitter = new Emitter();

class Store {
  constructor() {
    let themeType = localStorage.getItem('themeType');
    // console.info('FIRST STORE_INIT_CONSTANTS ', themeType);
    if (themeType === undefined || themeType === null) {
      themeType = true;
      localStorage.setItem('themeType', true);
    }
    this.store = STORE_INIT_CONSTANTS;

    dispatcher.register(
      function (payload) {
        switch (payload.type) {
          case CONFIGURE:
            this.configure(payload);
            break;
          case GET_BOOSTEDBALANCES:
            this.getBoostBalances();
            this.getBoostBalancesFarms();
            break;
          case GET_BALANCES:
            this.getBalancesFarms();
            this.getBalances();
            break;
          case GET_BALANCES_PERPETUAL:
            this.getBalancesPerpetualFarms();
            this.getBalancesPerpetual();
            break;
          case BOOST_STAKE:
            this.boostStake(payload);
            break;
          case STAKE:
            this.stake(payload);
            break;
          case EXCHANGE:
            this.exchange(payload);
            break;
          case BUY_LP:
            this.buyLP(payload);
            break;
          case WITHDRAW:
            this.withdraw(payload);
            break;
          case GET_REWARDS:
            this.getReward(payload);
            break;
          case EXIT:
            this.exit(payload);
            break;
          case GET_CLAIMABLE_ASSET:
            this.getClaimableAsset(payload);
            break;
          case CLAIM:
            this.claim(payload);
            break;
          case GET_CLAIMABLE:
            this.getClaimable(payload);
            break;

          default: {
          }
        }
      }.bind(this)
    );
  }

  getStore(key) {
    return this.store[key];
  }

  setStore(obj) {
    this.store = { ...this.store, ...obj };
    // // console.info('Store ---', this.store);
    emitter.emit('StoreUpdated');
  }

  configure = async () => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    const currentBlock = await web3.eth.getBlockNumber();
    // console.info('currentBlock', currentBlock);
    store.setStore({ currentBlock });

    window.setTimeout(() => {
      emitter.emit(CONFIGURE_RETURNED);
    }, 100);
  };

  getExchangeTokensPrice = async () => {
    const exchangeAssets = store.getStore('exchangeAssets').tokens;
    const assetOut = exchangeAssets.find((i) => i.label === 'USDC'); //USDC

    const assetsIn = exchangeAssets.filter(
      (a) =>
        a.group === 'outputs' &&
        (!a.onlyPurchaseableWith || a.onlyPurchaseableWith?.length < 2) &&
        a.label !== 'WBTC'
    );

    for (const assetIn of assetsIn) {
      try {
        console.info(assetIn.label);
        const amountOut = await store.getAmountOut(assetIn, assetOut, 1);
        console.info(assetOut, amountOut);
        assetIn.price = +amountOut;
      } catch (error) {
        console.info(error, assetIn);
      }
    }
  };

  getLpBalances = async () => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    const account = store.getStore('account');
    const lpTokens = store.getStore('lpTokens');
    const eth = this.getStore('poolInTokens').find((i) => i.label === 'ETH');
    const ethPrice = await store.getETHPrice();

    await Promise.all(lpTokens.map((assetOut) => this.getLpPrice(assetOut)));

    for (const lpToken of lpTokens) {
      const priceETH = await this.getLpAmountOut(eth, lpToken, `1`);
      lpToken.priceETH = priceETH;
      lpToken.price = ethPrice / priceETH;
      if (['BPT'].includes(lpToken.label)) {
        lpToken.price = await this._getOutputForBPTIndex(
          web3,
          lpToken,
          account
        );
        console.info(lpToken);
      }
    }

    store.setStore({ lpTokens });

    ///////////
    const methods = [
      'getStakedAmountUsd',
      'getTotalSupply',
      // 'getTotalLockVolume',
    ];
    const pools = store.getStore('rewardPools');
    const poolsPromises = pools.map(async (pool) => {
      const tokenPromises = pool.tokens.map(async (token) => {
        // console.info(web3, token, account);
        const methodPromises = methods.map((method) => this[method](token));
        const results = await Promise.all(methodPromises);
        token.stakedAmountUsd = results[0];
        token.totalSupply = results[1];

        // this is now getTotalLockVolume
        const lpToken = store
          .getStore('lpTokens')
          .find((token) => token.hiveId === pool.id);
        if (lpToken) {
          token.totalLockVolume = token.totalSupply * lpToken.price;
        }
        // console.info(token.name, token.totalSupply);
        // console.info(token.name, token.stakedAmountUsd);
        return token;
      });
      pool.tokens = await Promise.all(tokenPromises);
      return pool;
    });

    const poolData = await Promise.all(poolsPromises);
    // console.info(poolData);
    store.setStore({ rewardPools: poolData });
    emitter.emit(GET_ALL_BALANCES_RETURNED);
  };

  getBalancesPerpetualFarms = async () => {
    const methods = [
      '_getERC20Balance',
      '_getstakedBalance',
      '_getRewardsAvailable',
      '_getRatePerWeek',
      // '_getBonusAvailable',
    ];

    const pools = store.getStore('farmPools');
    const account = store.getStore('account');

    const web3 = new Web3(store.getStore('web3context').library.provider);

    // const currentBlock = await web3.eth.getBlockNumber();
    // store.setStore({ currentBlock });

    try {
      const poolsPromises = pools.map(async (pool) => {
        const tokenPromises = pool.tokens.map(async (token) => {
          // console.info(web3, token, account);
          const methodPromises = methods.map((method) =>
            balancesLib[method](web3, token, account)
          );

          let results = await Promise.all(methodPromises);
          // console.info(results);
          token.balance = results[0];
          token.stakedBalance = results[1];
          token.rewardsAvailable = results[2];
          token.ratePerWeek = results[3];

          token.beastModeBonus = results[4];

          return token;
        });

        pool.tokens = await Promise.all(tokenPromises);
        return pool;
      });
      // console.info(poolsPromises);
      let poolData = await Promise.all(poolsPromises);
      // console.info(poolData);
      store.setStore({ farmPools: poolData });
      emitter.emit(GET_BALANCES_PERPETUAL_RETURNED);
      emitter.emit(GET_BALANCES_RETURNED);
    } catch (error) {
      console.info(error);
      // return emitter.emit(ERROR, error);
    }
  };

  getBalancesPerpetual = async () => {
    const methods = [
      '_getERC20Balance',
      '_getstakedBalance',
      '_getRewardsAvailable',
      '_getRatePerWeek',
      '_getBonusAvailable',
    ];

    const pools = store.getStore('rewardPools');
    const account = store.getStore('account');
    const web3 = new Web3(store.getStore('web3context').library.provider);
    // const currentBlock = await web3.eth.getBlockNumber();
    // store.setStore({ currentBlock });

    try {
      const poolsPromises = pools.map(async (pool) => {
        if (pool.isSuperHive)
          pool.tokens[0] = {
            ...pool.tokens[0],
            ...(await store.tokenNFTs(pool)),
          };
        const tokenPromises = pool.tokens.map(async (token) => {
          // console.info(web3, token, account);
          const methodPromises = methods.map((method) =>
            balancesLib[method](web3, token, account)
          );

          let results = await Promise.all(methodPromises);
          // console.info(results);
          token.balance = results[0];
          token.stakedBalance = results[1];
          token.rewardsAvailable = results[2];
          token.ratePerWeek = results[3];
          token.beastModeBonus = results[4];

          return token;
        });

        pool.tokens = await Promise.all(tokenPromises);
        return pool;
      });
      // console.info(poolsPromises);
      let poolData = await Promise.all(poolsPromises);
      // console.info(poolData);
      store.setStore({ rewardPools: poolData });
      emitter.emit(GET_BALANCES_PERPETUAL_RETURNED);
      emitter.emit(GET_BALANCES_RETURNED);
    } catch (error) {
      console.info(error);
      // return emitter.emit(ERROR, error);
    }
  };

  /**
   *
   * @param {Token} token from which balance will be retrieved
   */
  getAssetBalance = async (token) => {
    const assets = store.getStore('exchangeAssets').tokens;

    try {
      const account = store.getStore('account');
      const web3 = new Web3(store.getStore('web3context').library.provider);

      let balance;

      if (token.label === 'ETH') {
        balance = await web3.eth.getBalance(account.address);
        return balance / 10 ** 18;
      }

      let isCombo = [
        'WPE+ETH',
        'YFU+ETH',
        'PIXEL+ETH',
        'STR+ETH',
        'M2+ETH',
      ].includes(token.label);
      if (isCombo) {
        // we want WPE, YFU, PIXEL, STR
        token = assets.find((i) => i.label === token.label.split('+')[0]);
      }

      return await balancesLib._getERC20Balance(web3, token, account, null);
    } catch (error) {
      return 0;
    }
  };

  getBalancesFarms = async () => {
    const methods = [
      '_getERC20Balance',
      '_getstakedBalance',
      '_getRewardsAvailable',
      '_getRatePerWeek',
    ];
    const pools = store.getStore('farmPools');
    const account = store.getStore('account');
    const web3 = new Web3(store.getStore('web3context').library.provider);

    try {
      const poolsPromises = pools.map(async (pool) => {
        const tokenPromises = pool.tokens.map(async (token) => {
          // console.info(web3, token, account);
          const methodPromises = methods.map((method) =>
            balancesLib[method](web3, token, account)
          );

          let results = await Promise.all(methodPromises);
          token.balance = results[0];
          token.stakedBalance = results[1];
          token.rewardsAvailable = results[2];
          token.ratePerWeek = results[3];
          return token;
        });

        pool.tokens = await Promise.all(tokenPromises);
        return pool;
      });
      // console.info(poolsPromises);
      let poolData = await Promise.all(poolsPromises);
      // console.info(poolData);
      store.setStore({ farmPools: poolData });
      emitter.emit(GET_BALANCES_RETURNED);
    } catch (error) {
      console.info(error);
      // return emitter.emit(ERROR, error);
    }
  };

  getBalances = async (singlePool) => {
    const methods = [
      '_getERC20Balance',
      '_getstakedBalance',
      '_getRewardsAvailable',
      //'_getUniswapLiquidity',
      '_getBalancerLiquidity',
      '_getRatePerWeek',
      '_getBonusAvailable',
    ];
    const pools = singlePool ? [singlePool] : store.getStore('rewardPools');
    const account = store.getStore('account');
    const web3 = new Web3(store.getStore('web3context').library.provider);

    try {
      const poolsPromises = pools.map(async (pool) => {
        if (pool.isSuperHive)
          pool.tokens[0] = {
            ...pool.tokens[0],
            ...(await store.tokenNFTs(pool)),
          };

        const tokenPromises = pool.tokens.map(async (token) => {
          // console.info(web3, token, account);
          const methodPromises = methods.map((method) =>
            balancesLib[method](web3, token, account)
          );

          let results = await Promise.all(methodPromises);
          // console.info(results);
          token.balance = results[0];
          token.stakedBalance = results[1];
          token.rewardsAvailable = results[2];
          if (pool.id === 'uniswap') {
            pool.liquidityValue = results[3];
          } else if (pool.id === 'balancer') {
            pool.liquidityValue = results[4];
          } else {
            pool.liquidityValue = 0;
          }
          token.ratePerWeek = results[5];
          // console.info
          token.beastModeBonus = results[6];

          return token;
        });

        pool.tokens = await Promise.all(tokenPromises);
        return pool;
      });
      // console.info(poolsPromises);
      const poolData = await Promise.all(poolsPromises);
      // console.info(poolData);
      if (singlePool) {
        const rewardPools = store.getStore('rewardPools');
        const poolIndex = rewardPools.findIndex(
          ({ id }) => id === singlePool.id
        );
        if (poolIndex !== -1) rewardPools[poolIndex] = poolData[0];
        store.setStore({ rewardPools });
      } else {
        store.setStore({ rewardPools: poolData });
      }
      emitter.emit(GET_BALANCES_RETURNED);
    } catch (error) {
      console.trace(error);
      // return emitter.emit(ERROR, error);
    }
  };

  getBoostBalancesFarms = async () => {
    const methods = [
      '_getBoosters',
      '_getBoosterCost',
      '_getBoostTokenBalance',
      '_getboostedBalances',
      '_getBoosterPrice',
      '_getNextBoostTime',
      '_getETHPrice',
    ];

    const pools = store.getStore('farmPools');
    const account = store.getStore('account');

    const web3 = new Web3(store.getStore('web3context').library.provider);

    try {
      const poolsPromises = pools.map(async (pool) => {
        const tokenPromises = pool.tokens.map(async (token) => {
          // console.info(web3, token, account);
          const methodPromises = methods.map((method) =>
            balancesLib[method](web3, token, account)
          );

          let results = await Promise.all(methodPromises);

          token.boosters = results[2];
          token.costBooster = results[1][0];
          token.boostBalance = results[2];
          token.costBoosterUSD = results[4] * token.costBooster;
          token.currentActiveBooster = results[0];
          token.currentBoosterStakeValue = results[3];
          token.stakeValueNextBooster = results[1][1];
          token.timeToNextBoost = results[5];
          token.ethPrice = results[6];

          return token;
        });

        pool.tokens = await Promise.all(tokenPromises);
        return pool;
      });
      // console.info(poolsPromises);
      let poolData = await Promise.all(poolsPromises);
      // console.info(poolData);
      store.setStore({ farmPools: poolData });
      emitter.emit(GET_BOOSTEDBALANCES_RETURNED);
    } catch (error) {
      console.info(error);
      // return emitter.emit(ERROR, error);
    }
  };

  getBoostBalances = async () => {
    const methods = [
      '_getBoosters',
      '_getBoosterCost',
      '_getBoostTokenBalance',
      '_getboostedBalances',
      '_getBoosterPrice',
      '_getNextBoostTime',
      '_getETHPrice',
      '_boostedTotalSupply',
    ];
    const pools = store.getStore('rewardPools');
    const account = store.getStore('account');
    // console.info('account', account);
    const web3 = new Web3(store.getStore('web3context').library.provider);
    // const web3 = new Web3(window.ethereum);

    // const currentBlock = await web3.eth.getBlockNumber();
    // store.setStore({ currentBlock });

    try {
      const poolsPromises = pools.map(async (pool) => {
        if (pool.isSuperHive)
          pool.tokens[0] = {
            ...pool.tokens[0],
            ...(await store.tokenNFTs(pool)),
          };
        const tokenPromises = pool.tokens.map(async (token) => {
          const methodPromises = methods.map((method) =>
            balancesLib[method](web3, token, account)
          );

          let results = await Promise.all(methodPromises);
          token.boosters = results[2];
          token.costBooster = results[1][0];
          token.boostBalance = results[2];
          token.costBoosterUSD = results[4] * token.costBooster;
          token.currentActiveBooster = results[0];
          token.currentBoosterStakeValue = results[3];
          token.stakeValueNextBooster = results[1][1];
          token.timeToNextBoost = results[5];
          token.ethPrice = results[6];
          token.boostedTotalSupply = results[7];

          return token;
        });

        pool.tokens = await Promise.all(tokenPromises);
        return pool;
      });
      // console.info(poolsPromises);
      let poolData = await Promise.all(poolsPromises);
      // console.info(poolData);
      store.setStore({ rewardPools: poolData });
      emitter.emit(GET_BOOSTEDBALANCES_RETURNED);
    } catch (error) {
      // console.info(error);
      // return emitter.emit(ERROR, error);
    }
  };

  getPrice = async (assetIn, assetOut) => {
    try {
      if (assetIn.label === assetOut.label) return 0;
      // console.info(assetIn, assetOut);
      // console.info(
      //   'in',
      //   assetIn.label,
      //   assetIn.address,
      //   'out',
      //   assetOut.label,
      //   assetOut.address
      // );

      const account = store.getStore('account');
      if (!store.getStore('web3context')) return 0;
      const web3 = new Web3(store.getStore('web3context').library.provider);
      const assets = store.getStore('exchangeAssets').tokens;

      //const { assetIn, assetOut} = payload.content;
      let route = [];
      if (assetIn.label !== 'ETH') {
        route.push(assetIn.address);
      }
      route = route.concat([
        '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        '0xd075e95423c5c4ba1e122cae0f4cdfa19b82881b',
      ]);
      route.push(assetOut.address);

      const amounts = await this._getAmountsOut(
        web3,
        assetIn,
        assetOut,
        route,
        '100',
        account
      ); // => {

      // console.info(amounts);

      const amountOut = (
        amounts[amounts.length - 1] /
        10 ** assetOut.decimals
      ).toFixed(4);
      const price = 100 / amountOut;

      //ETH price

      const currentETH = assets.find((i) => i.label === 'ETH');
      currentETH.price =
        100 /
        (amounts[amounts.length - 3] / 10 ** currentETH.decimals).toFixed(4);

      //WPE price
      const currentWPE = assets.find((i) => i.label === 'WPE');
      const wpeTemp = (
        amounts[amounts.length - 2] /
        10 ** currentWPE.decimals
      ).toFixed(4);
      currentWPE.price = 100 / wpeTemp;

      const current = assets.find((i) => i.address === assetOut.address);
      current.price = price;
      return !Number.isNaN(price) ? price : 0;
    } catch (error) {
      console.info(error);
    }
  };

  getLpPrice = async (assetOut) => {
    if (!store.getStore('web3context')) return 0;
    const account = store.getStore('account');
    const web3 = new Web3(store.getStore('web3context').library.provider);
    const assets = store.getStore('lpTokens');

    let price;
    // console.info(assetOut);
    if (assetOut.label === 'ETH' || assetOut.label === 'WPE') {
      const temp = { label: 'STR' };
      const test = await this._getLPprice(web3, temp, account); //set an LP token address for the moment
      price = (test[test.length - 3] / 100 / 10 ** 6).toFixed(4);

      if (assetOut.label === 'ETH') {
        const priceETH = (test[test.length - 2] / 100 / 10 ** 18).toFixed(4);
        const eth = assets.find((i) => i.label === 'ETH');
        eth.price = price / priceETH;
        price = eth.price;
      } else {
        const priceWPE = (test[test.length - 1] / 100 / 10 ** 18).toFixed(4);
        const wpe = assets.find((i) => i.label === 'WPE');
        wpe.price = price / priceWPE;
        price = wpe.price;
      }
    } else {
      const test = await this._getLPprice(web3, assetOut, account);

      price = (test[test.length - 3] / 100 / 10 ** 6).toFixed(4);
      const priceETH = (test[test.length - 2] / 100 / 10 ** 18).toFixed(4);
      const priceWPE = (test[test.length - 1] / 100 / 10 ** 18).toFixed(4);
      const current = assets.find((i) => i.address === assetOut.address);

      current.price = !Number.isNaN(price) ? price : 0;
      current.priceETH = !Number.isNaN(priceETH) ? priceETH : 0;
      current.priceWPE = !Number.isNaN(priceWPE) ? priceWPE : 0;
    }

    return !Number.isNaN(price) ? price : 0;
  };

  /**
   *
   * @param {Token} assetIn
   * @param {Token} assetOut
   * @param {Number} amountIn
   * @returns {Number} amount out
   */
  getAmountOut = async (assetIn, assetOut, amountIn) => {
    try {
      const account = store.getStore('account');
      if (!store.getStore('web3context')) return 0;
      const web3 = new Web3(store.getStore('web3context').library.provider);
      const assets = store.getStore('exchangeAssets').tokens;
      var current = assets.find((i) => i.address === assetOut.address);

      var inputToken = assetIn;
      var outputToken = assetOut;
      var midRoute = current.route;

      if (assetIn.group === 'outputs') {
        inputToken = assetOut;
        outputToken = assetIn;
        var temp = assets.find((i) => i.address === outputToken.address);
        midRoute = temp.route;
      }

      var route = [];

      if (inputToken.label !== 'ETH') {
        route.push(inputToken.address);
      }
      //Default route
      route = route.concat(midRoute);
      route.push(outputToken.address);

      if (assetIn.group === 'outputs') route = route.reverse();

      const amounts = await this._getAmountsOut(
        web3,
        assetIn,
        assetOut,
        route,
        amountIn,
        account
      );

      if (!amounts || !amounts.length) return 0;
      return (amounts[amounts.length - 1] / 10 ** assetOut.decimals).toFixed(9);
    } catch (error) {
      console.info(error);
      throw error;
    }
  };

  getLpAmountOut = async (assetIn, assetOut, amountIn) => {
    // console.info(assetOut);
    const assets = store.getStore('lpTokens');
    const account = store.getStore('account');

    let current = assets.find((i) => i.address === assetOut.address);

    // console.info('current', current, assetOut.address);

    if (!store.getStore('web3context')) return 0;
    const web3 = new Web3(store.getStore('web3context').library.provider);
    let amountOut;
    // console.info('current', current);
    //LP price

    try {
      if (assetIn.label === 'ETH') {
        if (current.label === 'WPE') {
          amountOut = await this._getOutputForWPELP(web3, amountIn, account);
        } else if (current.label === 'WBTC') {
          amountOut = await this._getOutputForWBTCLP(web3, amountIn, account);
        } else if (current.label === 'WPEBPT') {
          amountOut = await this._getOutputForWPEBPT(web3, amountIn, account);
        } else if (current.label === 'YFUBPT') {
          amountOut = await this._getOutputForYFUBPT(web3, amountIn, account);
        } else if (current.label === 'PIXELBPT') {
          amountOut = await this._getOutputForPIXELBPT(web3, amountIn, account);
        } else if (current.label === 'STRBPT') {
          amountOut = await this._getOutputForSTRBPT(web3, amountIn, account);
        } else if (current.label === 'M2BPT') {
          amountOut = await this._getOutputForM2BPT(web3, amountIn, account);
        } else {
          amountOut = amountIn * (1 / current.priceETH);
        }
      } else if (assetIn.label === 'WPE') {
        amountOut = amountIn * (1 / current.priceWPE);
      } else if (assetIn.label === 'WPE+ETH') {
        amountOut = await this._getOutputForWPEBPTwToken(
          web3,
          amountIn,
          account
        );
      } else if (assetIn.label === 'YFU+ETH') {
        amountOut = await this._getOutputForYFUBPTwToken(
          web3,
          amountIn,
          account
        );
      } else if (assetIn.label === 'PIXEL+ETH') {
        amountOut = await this._getOutputForPIXELBPTwToken(
          web3,
          amountIn,
          account
        );
      } else if (assetIn.label === 'STR+ETH') {
        amountOut = await this._getOutputForSTRBPTwToken(
          web3,
          amountIn,
          account
        );
      } else if (assetIn.label === 'M2+ETH') {
        amountOut = await this._getOutputForM2BPTwToken(
          web3,
          amountIn,
          account
        );
      } else {
        //stable coin
        amountOut = amountIn * (1 / current.price);
      }

      return amountOut;
    } catch (error) {
      console.info(error);
      throw error;
    }
  };

  getEthLpAmountOut = async (assetIn, assetOut, amountIn) => {
    // console.info(assetOut);
    const assets = store.getStore('lpTokens');
    const account = store.getStore('account');

    var current = assets.find((i) => i.address === assetOut.address);

    if (!store.getStore('web3context')) return 0;
    const web3 = new Web3(store.getStore('web3context').library.provider);
    let amountOut;
    // console.info('current', current);
    //LP price

    try {
      if (assetIn.label === 'WPE+ETH') {
        amountOut = await this._getOutputForWPEBPTwToken(
          web3,
          amountIn,
          account,
          1
        );
      } else if (assetIn.label === 'YFU+ETH') {
        amountOut = await this._getOutputForYFUBPTwToken(
          web3,
          amountIn,
          account,
          1
        );
      } else if (assetIn.label === 'PIXEL+ETH') {
        amountOut = await this._getOutputForPIXELBPTwToken(
          web3,
          amountIn,
          account,
          1
        );
      } else if (assetIn.label === 'STR+ETH') {
        amountOut = await this._getOutputForSTRBPTwToken(
          web3,
          amountIn,
          account,
          1
        );
      } else if (assetIn.label === 'M2+ETH') {
        amountOut = await this._getOutputForM2BPTwToken(
          web3,
          amountIn,
          account,
          1
        );
      } else {
        //stable coin
        amountOut = amountIn * (1 / current.price);
      }

      return amountOut;
    } catch (error) {
      console.info(error);
      throw error;
    }
  };

  // esta función regresa el precio del lp token. (lp price en la tabla de boosted)
  _getOutputForBPTIndex = async (web3, asset, account, index = 0) => {
    // WPE contract
    //GetAmount of WPE in Index Balancer
    //Divide by 0.8 to get total value
    //Multiply by WPE Price
    //Divide totalValue in total amount of BPT
    //Return value per BPT

    //WPE contract
    const wpeContract = new web3.eth.Contract(
      config.erc20ABI,
      config.yfiAddress
    );

    //Index contract
    const contract = new web3.eth.Contract(
      config.erc20ABI,
      config.balancerAddress
    );

    const wpeToken = store
      .getStore('exchangeAssets')
      .tokens.find((t) => t.label === 'WPE');

    try {
      const wpeAmount = await wpeContract.methods
        .balanceOf(config.balancerAddress)
        .call({ from: account.address });
      const wpeTotal = parseFloat(wpeAmount) / 0.8;
      const wpeValue = wpeTotal * wpeToken.price;
      const amountBPT = await contract.methods
        .totalSupply()
        .call({ from: account.address });
      const valuePerBPT = wpeValue / amountBPT;
      return valuePerBPT;
    } catch (ex) {
      console.info(ex);
      throw ex;
    }
  };

  _getOutputForWPEBPTwToken = async (web3, amountIn, account, index = 0) => {
    console.info('_getOutputForWPEBPTwToken index', index);
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.WPEBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[index] / 10 ** 18).toFixed(4);
    } catch (error) {
      throw error;
    }
  };

  _getOutputForYFUBPTwToken = async (web3, amountIn, account, index = 0) => {
    console.info('_getOutputForYFUBPTwToken index', index);
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.YFUBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[index] / 10 ** 18).toFixed(4);
    } catch (error) {
      throw error;
    }
  };

  _getOutputForPIXELBPTwToken = async (web3, amountIn, account, index = 0) => {
    console.info('_getOutputForPIXELBPTwToken index', index);
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.PIXELBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[index] / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForSTRBPTwToken = async (web3, amountIn, account, index = 0) => {
    console.info('_getOutputForSTRBPTwToken index', index);
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.STRBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[index] / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForM2BPTwToken = async (web3, amountIn, account, index = 0) => {
    console.info('_getOutputForM2BPTwToken index', index);
    const contract = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.M2BPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await contract.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[index] / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForWPEBPTwTokenEthVal = async (web3, amountIn, account) => {
    // wpeLPExchange
    const contract = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.WPEBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await contract.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[1] / 10 ** 18).toFixed(4);
    } catch (error) {
      throw error;
    }
  };

  _getOutputForYFUBPTwTokenEthVal = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.YFUBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[1] / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForSTRBPTwTokenEthVal = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.STRBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[1] / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForM2BPTwTokenEthVal = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.M2BPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[1] / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForPIXELBPTwTokenEthVal = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.PIXELBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountForToken(amountToSend)
        .call({ from: account.address });
      console.info(amount);
      return (amount[1] / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForYFUBPT = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.YFUBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountFor(amountToSend) //[assetIn.address, assetOut.address])
        .call({ from: account.address });
      // console.info(amount);
      return (amount / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForSTRBPT = async (web3, amountIn, account) => {
    // wpeLPExchange
    let contract = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.STRBPTbptAddress
    );
    const amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await contract.methods
        .getAmountFor(amountToSend) //[assetIn.address, assetOut.address])
        .call({ from: account.address });
      return (amount / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForM2BPT = async (web3, amountIn, account) => {
    // wpeLPExchange
    let contract = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.M2BPTbptAddress
    );
    const amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await contract.methods
        .getAmountFor(amountToSend) //[assetIn.address, assetOut.address])
        .call({ from: account.address });
      return (amount / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForPIXELBPT = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.PIXELBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountFor(amountToSend) //[assetIn.address, assetOut.address])
        .call({ from: account.address });
      // console.info(amount);
      return (amount / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForWPEBPT = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config.WPEBPTbptAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountFor(amountToSend) //[assetIn.address, assetOut.address])
        .call({ from: account.address });
      // console.info(amount);
      return (amount / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForWPELP = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPElpAddressABI,
      config.WPElpAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountFor(amountToSend) //[assetIn.address, assetOut.address])
        .call({ from: account.address });
      return (amount / 10 ** 18).toFixed(4);
    } catch (ex) {
      throw ex;
    }
  };

  _getOutputForWBTCLP = async (web3, amountIn, account) => {
    let wpeLPExchange = new web3.eth.Contract(
      config.WPElpAddressABI,
      config.WBTClpAddress
    );
    var amountToSend = web3.utils.toWei(amountIn, 'ether');

    try {
      const amount = await wpeLPExchange.methods
        .getAmountFor(amountToSend) //[assetIn.address, assetOut.address])
        .call({ from: account.address });
      // console.info(amount);
      return (amount / 10 ** 18).toFixed(12);
    } catch (ex) {
      throw ex;
    }
  };

  _getAmountsOut = async (
    web3,
    assetIn,
    assetOut,
    route,
    amountIn,
    account
  ) => {
    let uniswapRouter = new web3.eth.Contract(
      config.uniswapRouterABI,
      config.uniswapRouterAddress
    );
    var amountToSend = web3.utils.toWei(
      (+amountIn).toFixed(18).toString(),
      'ether'
    );
    if (assetIn.decimals !== 18) {
      amountToSend = (amountIn * 10 ** assetIn.decimals).toFixed(0);
    }
    try {
      const amounts = await uniswapRouter.methods
        .getAmountsOut(amountToSend, route)
        .call({ from: account.address });
      return amounts;
      //callback(null, amounts);
    } catch (ex) {
      throw ex;
    }
  };

  _getLPprice = async (web3, assetOut, account) => {
    //get coin lp contract address
    const contractKey = assetOut.label + 'lpAddress';

    //FOR THE REST OF THE COINS // coinRouter
    const contract = new web3.eth.Contract(
      config.lpAddressABI,
      config[contractKey]
    );
    if (!config[contractKey]) return {};

    try {
      const amounts = await contract.methods
        .getPriceFor(web3.utils.toWei('100', 'ether'))
        .call({ from: account.address });

      // console.info(contract);
      // console.info(amounts);
      return amounts;
    } catch (ex) {
      // console.info(ex);
      // throw ex
      return ex;
    }
  };

  _checkApprovalLiquidityBPT = async (
    asset, // real in YFU, STR, PIXEL
    assetOut,
    account,
    amount,
    callback
  ) => {
    try {
      const web3 = new Web3(store.getStore('web3context').library.provider);

      console.info(asset);
      console.info(assetOut);

      let contractLPSell = config[assetOut.label + 'bptAddress'];
      console.info(contractLPSell);
      const erc20Contract = new web3.eth.Contract(
        config.erc20ABI,
        asset.address
      );
      const allowance = await erc20Contract.methods
        .allowance(account.address, contractLPSell)
        .call({ from: account.address });

      const ethAllowance = web3.utils.fromWei(allowance, 'ether');

      if (parseFloat(ethAllowance) < parseFloat(amount)) {
        await erc20Contract.methods
          .approve(contractLPSell, web3.utils.toWei('999999999999999', 'ether'))
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
          });
        callback();
      } else {
        callback();
      }
    } catch (error) {
      // console.info(error);
      if (error.message) {
        return callback(error.message);
      }
      callback(error);
    }
  };

  _checkApprovalLiquidity = async (
    asset,
    assetOut,
    account,
    amount,
    callback
  ) => {
    try {
      const web3 = new Web3(store.getStore('web3context').library.provider);

      console.info(asset);
      console.info(assetOut);

      let contractLPSell = config[assetOut.label + 'lpAddress'];
      console.info(contractLPSell);
      const erc20Contract = new web3.eth.Contract(
        config.erc20ABI,
        asset.address
      );
      const allowance = await erc20Contract.methods
        .allowance(account.address, contractLPSell)
        .call({ from: account.address });

      const ethAllowance = web3.utils.fromWei(allowance, 'ether');

      if (parseFloat(ethAllowance) < parseFloat(amount)) {
        await erc20Contract.methods
          .approve(contractLPSell, web3.utils.toWei('999999999999999', 'ether'))
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
          });
        callback();
      } else {
        callback();
      }
    } catch (error) {
      // console.info(error);
      if (error.message) {
        return callback(error.message);
      }
      callback(error);
    }
  };

  _checkApprovalExchange = async (asset, account, amount, callback) => {
    try {
      const web3 = new Web3(store.getStore('web3context').library.provider);

      const erc20Contract = new web3.eth.Contract(
        config.erc20ABI,
        asset.address
      );
      const allowance = await erc20Contract.methods
        .allowance(account.address, config.exchangeAddress)
        .call({ from: account.address });

      const ethAllowance = web3.utils.fromWei(allowance, 'ether');

      if (parseFloat(ethAllowance) < parseFloat(amount)) {
        await erc20Contract.methods
          .approve(
            config.exchangeAddress,
            web3.utils.toWei('999999999999999', 'ether')
          )
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
          });
        callback();
      } else {
        callback();
      }
    } catch (error) {
      // console.info(error);
      if (error.message) {
        return callback(error.message);
      }
      callback(error);
    }
  };

  checkAllowance = async (asset, contract) => {
    // console.info('check allowance asset ----------', asset);

    try {
      const account = store.getStore('account');
      const web3 = new Web3(store.getStore('web3context').library.provider);
      const assets = store.getStore('exchangeAssets').tokens;

      const isCombo = [
        'WPE+ETH',
        'YFU+ETH',
        'PIXEL+ETH',
        'STR+ETH',
        'M2+ETH',
      ].includes(asset.address);

      if (isCombo) {
        if (asset.address === 'WPE+ETH') {
          asset = assets.find((i) => i.label === 'WPE');
        } else if (asset.address === 'YFU+ETH') {
          asset = assets.find((i) => i.label === 'YFU');
        } else if (asset.address === 'PIXEL+ETH') {
          asset = assets.find((i) => i.label === 'PIXEL');
        } else if (asset.address === 'STR+ETH') {
          asset = assets.find((i) => i.label === 'STR');
        } else if (asset.address === 'M2+ETH') {
          asset = assets.find((i) => i.label === 'M2');
        }
        contract = config[asset.label + 'BPTbptAddress'];
      }

      // console.info('check allowance asset ----------', asset);
      const erc20Contract = new web3.eth.Contract(
        config.erc20ABI,
        asset.address
      );

      const allowance = await erc20Contract.methods
        .allowance(account.address, contract)
        .call({ from: account.address });
      return allowance;
    } catch (error) {
      console.info(error);
      return 0;
    }
  };

  _checkApproval = async (asset, account, amount, contract, callback) => {
    try {
      const web3 = new Web3(store.getStore('web3context').library.provider);
      console.info(asset);
      const erc20Contract = new web3.eth.Contract(
        config.erc20ABI,
        asset.address
      );
      const allowance = await erc20Contract.methods
        .allowance(account.address, contract)
        .call({ from: account.address });

      const ethAllowance = web3.utils.fromWei(allowance, 'ether');
      console.info(allowance);
      console.info(ethAllowance);

      if (parseFloat(ethAllowance) < parseFloat(amount)) {
        await erc20Contract.methods
          .approve(contract, web3.utils.toWei('999999999999999', 'ether'))
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
          });
        callback();
      } else {
        callback();
      }
    } catch (error) {
      // console.info(error);
      if (error.message) {
        return callback(error.message);
      }
      callback(error);
    }
  };

  _checkApprovalWaitForConfirmation = async (
    asset,
    account,
    amount,
    contract,
    callback
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    let erc20Contract = new web3.eth.Contract(config.erc20ABI, asset.address);
    const allowance = await erc20Contract.methods
      .allowance(account.address, contract)
      .call({ from: account.address });

    const ethAllowance = web3.utils.fromWei(allowance, 'ether');

    if (parseFloat(ethAllowance) < parseFloat(amount)) {
      erc20Contract.methods
        .approve(contract, web3.utils.toWei('999999999999999', 'ether'))
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        })
        .on('transactionHash', function (hash) {
          callback();
        })
        .on('error', function (error) {
          if (!error.toString().includes('-32601')) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    } else {
      callback();
    }
  };

  _getBoostBalanceAvailable = async (web3, asset, account, callback) => {
    let boostTokenContract = new web3.eth.Contract(
      config.erc20ABI,
      config.boostTokenAddress
    );
    let boostContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );
    // console.info('++++++++++' + asset + '+++++++++++');
    try {
      var boosters = await boostContract.methods
        .numBoostersBought(account.address)
        .call({ from: account.address }); //await erc20Contract.methods.balanceOf(account.address).call({ from: account.address });
      var balance = parseFloat(balance) / 10 ** asset.decimals;

      asset.boostBalance = await boostTokenContract.methods
        .balanceOf(account.address)
        .call({ from: account.address });

      var boostedPriceFuture = await boostContract.methods
        .getBoosterPrice(account.address)
        .call({ from: account.address });
      // console.info('>>>>>>>' + boostedPriceFuture);

      asset.costBooster = boostedPriceFuture[0] / 10 ** asset.decimals;
      asset.costBoosterUSD = 0;
      asset.currentActiveBooster = boosters;
      asset.currentBoosterStakeValue = 0;
      asset.stakeValueNextBooster =
        boostedPriceFuture[1] / 10 ** asset.decimals;
      // console.info('>>>>>>>' + balance);
      // console.info('>>>>>>>' + boostedPriceFuture);

      if (callback && typeof callback === 'function') {
        callback(null, parseFloat(balance));
      } else {
        return parseFloat(balance);
      }
    } catch (ex) {
      if (callback && typeof callback === 'function') {
        callback(ex);
      } else {
        throw ex;
      }
    }
  };

  // apparently this is not used anywhere
  _checkIfApprovalIsNeeded = async (
    asset,
    account,
    amount,
    contract,
    callback,
    overwriteAddress
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    let erc20Contract = new web3.eth.Contract(
      config.erc20ABI,
      overwriteAddress ? overwriteAddress : asset.address
    );
    const allowance = await erc20Contract.methods
      .allowance(account.address, contract)
      .call({ from: account.address });

    const ethAllowance = web3.utils.fromWei(allowance, 'ether');
    if (parseFloat(ethAllowance) < parseFloat(amount)) {
      asset.amount = amount;
      callback(null, asset);
    } else {
      callback(null, false);
    }
  };

  _callApproval = async (
    asset,
    account,
    amount,
    contract,
    last,
    callback,
    overwriteAddress
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    let erc20Contract = new web3.eth.Contract(
      config.erc20ABI,
      overwriteAddress ? overwriteAddress : asset.address
    );
    try {
      if (last) {
        await erc20Contract.methods
          .approve(contract, web3.utils.toWei('999999999999999', 'ether'))
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
          });
        callback();
      } else {
        erc20Contract.methods
          .approve(contract, web3.utils.toWei('999999999999999', 'ether'))
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
          })
          .on('transactionHash', (hash) => {
            callback();
          })
          .on('error', (error) => {
            if (!error.toString().includes('-32601')) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      }
    } catch (error) {
      if (error.message) {
        return callback(error.message);
      }
      callback(error);
    }
  };

  /**
   * Asks for permission for spending a token.
   * @param {Token} asset Object with address attribute which corresponds to the token you are asking for permission
   * @return {Promise} A promise which is resolved when metamask finishs asking for aproval, regardless if is approve or not.
   */
  askApproval = async (asset) => {
    const account = store.getStore('account');
    let contract = config.exchangeAddress;
    const assets = store.getStore('exchangeAssets').tokens;

    const isCombo = [
      'WPE+ETH',
      'YFU+ETH',
      'PIXEL+ETH',
      'STR+ETH',
      'M2+ETH',
    ].includes(asset.address);
    if (isCombo) {
      // we want WPE, YFU, PIXEL, STR
      asset = assets.find((i) => i.label === asset.address.split('+')[0]);
      contract = config[asset.label + 'BPTbptAddress'];
    }
    console.info(asset);

    return new Promise((res, rej) => {
      try {
        this._callApproval(asset, account, 0, contract, null, (err) =>
          err ? rej(err) : res()
        );
      } catch (e) {
        console.info('error');
        rej(e);
      }
    });
  };

  /**
   * -------------------------
   * EXCHANGE LOGIC
   * ----------------------------
   */

  buyWithEth = (payload) => {
    const account = store.getStore('account');
    const { assetOut, amountIn, amountOut } = payload.content;

    if (assetOut.label === 'WPE') {
      this._buyWpeWithEthCall(
        assetOut,
        account,
        amountOut,
        amountIn,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }
          return emitter.emit(EXCHANGE_RETURNED, res); //EXCHANGEETHFORTOKEN_RETURNED
        }
      );
    } else {
      this._buyWithEthCall(
        assetOut,
        account,
        amountOut,
        amountIn,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }
          return emitter.emit(EXCHANGE_RETURNED, res); //EXCHANGEETHFORTOKEN_RETURNED
        }
      );
    }
  };

  _buyWpeWithEthCall = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const exchangeContract = new web3.eth.Contract(
      config.exchangeABI,
      config.exchangeAddress
    );

    exchangeContract.methods
      .buyWPEWithEth(0)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(value, 'ether'),
      })
      .on('transactionHash', function (hash) {
        console.info('exchangeing 1', hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        console.info('exchangeing 1', receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  _buyWithEthCall = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    amount = parseInt(amount);
    const exchangeContract = new web3.eth.Contract(
      config.exchangeABI,
      config.exchangeAddress
    );

    exchangeContract.methods
      .buyTokenWithEth(asset.address, amount) //address tokenOut, uint256 amountOut SET TO 0 FOR TEST PURPOSES
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(value, 'ether'),
      })
      .on('transactionHash', function (hash) {
        console.info('exchangeing 1', hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        console.info('exchangeing 1', receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };
  buyWithToken = (payload) => {
    const account = store.getStore('account');
    const { assetIn, assetOut, amountIn, amountOut } = payload.content;

    this._checkApprovalExchange(assetIn, account, amountIn, (err) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      this._buyWithTokenCall(
        assetIn,
        assetOut,
        amountIn,
        amountOut,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(EXCHANGE_RETURNED, res);
        }
      );
    });
  };

  buyWpeWithToken = (payload) => {
    const account = store.getStore('account');
    const { assetIn, assetOut, amountIn, amountOut } = payload.content;

    this._checkApprovalExchange(assetIn, account, amountIn, (err) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      this._buyWpeWithToken(
        assetIn,
        assetOut,
        amountIn,
        amountOut,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(EXCHANGE_RETURNED, res);
        }
      );
    });
  };

  _buyWpeWithToken = async (
    assetIn,
    assetOut,
    amountIn,
    amountOut,
    account,
    callback
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const exchangeContract = new web3.eth.Contract(
      config.exchangeABI,
      config.exchangeAddress
    );

    var amountToSend = web3.utils.toWei(amountIn, 'ether');
    if (assetIn.decimals !== 18) {
      amountToSend = (amountIn * 10 ** assetIn.decimals).toFixed(0);
    }
    let amount = parseInt(amountOut);
    console.log('test sell token to wpe', assetIn.address, amountToSend);

    exchangeContract.methods
      .sellTokenToWPE(assetIn.address, amountToSend, amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  _buyWithTokenCall = async (
    assetIn,
    assetOut,
    amountIn,
    amountOut,
    account,
    callback
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const exchangeContract = new web3.eth.Contract(
      config.exchangeABI,
      config.exchangeAddress
    );

    var amountToSend = web3.utils.toWei(amountIn, 'ether');
    if (assetIn.decimals !== 18) {
      amountToSend = (amountIn * 10 ** assetIn.decimals).toFixed(0);
    }
    let amount = parseInt(amountOut);
    exchangeContract.methods
      .buyTokenWithToken(
        assetIn.address,
        assetOut.address,
        amountToSend,
        amount
      ) //IERC20 tokenIn, address tokenOut, uint256 amountIn, uint256 amountOut
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };
  sellWithToken = (payload) => {
    const account = store.getStore('account');
    const { assetIn, assetOut, amountIn, amountOut } = payload.content;

    this._checkApprovalExchange(assetIn, account, amountIn, (err) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      this._sellWithTokenCall(
        assetIn,
        assetOut,
        amountIn,
        amountOut,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(EXCHANGE_RETURNED, res);
        }
      );
    });
  };
  _sellWithTokenCall = async (
    assetIn,
    assetOut,
    amountIn,
    amountOut,
    account,
    callback
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const exchangeContract = new web3.eth.Contract(
      config.exchangeABI,
      config.exchangeAddress
    );

    var amountToSend = web3.utils.toWei(amountIn, 'ether');
    if (assetIn.decimals !== 18) {
      amountToSend = (amountIn * 10 ** assetIn.decimals).toFixed(0);
    }
    let amount = parseInt(amountOut);
    exchangeContract.methods
      .sellTokenToToken(assetIn.address, assetOut.address, amountToSend, amount) //IERC20 tokenIn, address tokenOut, uint256 amountIn, uint256 amountOut
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  sellWithTokenToEth = (payload) => {
    const account = store.getStore('account');
    const { assetIn, assetOut, amountIn, amountOut } = payload.content;

    this._checkApprovalExchange(assetIn, account, amountIn, (err) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      this._sellWithTokenToEthCall(
        assetIn,
        assetOut,
        amountIn,
        amountOut,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(EXCHANGE_RETURNED, res);
        }
      );
    });
  };

  _sellWithTokenToEthCall = async (
    assetIn,
    assetOut,
    amountIn,
    amountOut,
    account,
    callback
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const exchangeContract = new web3.eth.Contract(
      config.exchangeABI,
      config.exchangeAddress
    );

    var amountToSend = web3.utils.toWei(amountIn, 'ether');
    if (assetIn.decimals !== 18) {
      amountToSend = (amountIn * 10 ** assetIn.decimals).toFixed(0);
    }
    let amount = parseInt(amountOut);

    exchangeContract.methods
      .sellTokenToEth(assetIn.address, amountToSend, amount) //IERC20 tokenIn, address tokenOut, uint256 amountIn, uint256 amountOut
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  sellWpeToEth = (payload) => {
    const account = store.getStore('account');
    const { assetIn, assetOut, amountIn, amountOut } = payload.content;

    this._checkApprovalExchange(assetIn, account, amountIn, (err) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      this._sellWpeToEth(
        assetIn,
        assetOut,
        amountIn,
        amountOut,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(EXCHANGE_RETURNED, res);
        }
      );
    });
  };

  _sellWpeToEth = async (
    assetIn,
    assetOut,
    amountIn,
    amountOut,
    account,
    callback
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const exchangeContract = new web3.eth.Contract(
      config.exchangeABI,
      config.exchangeAddress
    );

    var amountToSend = web3.utils.toWei(amountIn, 'ether');
    if (assetIn.decimals !== 18) {
      amountToSend = (amountIn * 10 ** assetIn.decimals).toFixed(0);
    }
    let amount = parseInt(amountOut);
    exchangeContract.methods
      .sellWPEToEth(amountToSend, amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  buyTokenWithWPE = (payload) => {
    const account = store.getStore('account');
    const { assetIn, assetOut, amountIn, amountOut } = payload.content;

    this._checkApprovalExchange(assetIn, account, amountIn, (err) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      this._buyTokenWithWPE(
        assetIn,
        assetOut,
        amountIn,
        amountOut,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(EXCHANGE_RETURNED, res);
        }
      );
    });
  };

  _buyTokenWithWPE = async (
    assetIn,
    assetOut,
    amountIn,
    amountOut,
    account,
    callback
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const exchangeContract = new web3.eth.Contract(
      config.exchangeABI,
      config.exchangeAddress
    );

    var amountToSend = web3.utils.toWei(amountIn, 'ether');
    if (assetIn.decimals !== 18) {
      amountToSend = (amountIn * 10 ** assetIn.decimals).toFixed(0);
    }
    let amount = parseInt(amountOut);
    exchangeContract.methods
      .buyTokenWithWPE(assetOut.address, amountToSend, amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  exchange = (payload) => {
    const { assetIn, assetOut } = payload.content;
    if (assetIn.group === 'inputs') {
      if (assetOut.label === 'WPE' && assetIn.label !== 'ETH') {
        this.buyWpeWithToken(payload);
      } else if (assetIn.label === 'ETH') {
        this.buyWithEth(payload);
      } else {
        this.buyWithToken(payload);
      }
    } // vender tokens
    else {
      if (assetIn.label === 'WPE') {
        if (assetOut.label === 'ETH') {
          this.sellWpeToEth(payload);
        } else {
          this.buyTokenWithWPE(payload);
        }
      } else if (assetOut.label === 'ETH') {
        this.sellWithTokenToEth(payload);
      } else {
        this.sellWithToken(payload);
      }
    }
  };

  buyLPWithCombo = (payload) => {
    const account = store.getStore('account');
    const { assetIn, assetOut, amountIn, amountOut } = payload.content;
    const assets = store.getStore('exchangeAssets').tokens;

    console.info(assetOut);
    let realIn;
    if (assetIn.label === 'WPE+ETH') {
      realIn = assets.find((i) => i.label === 'WPE');
    } else if (assetIn.label === 'YFU+ETH') {
      realIn = assets.find((i) => i.label === 'YFU');
    } else if (assetIn.label === 'PIXEL+ETH') {
      realIn = assets.find((i) => i.label === 'PIXEL');
    } else if (assetIn.label === 'STR+ETH') {
      realIn = assets.find((i) => i.label === 'STR');
    } else if (assetIn.label === 'M2+ETH') {
      realIn = assets.find((i) => i.label === 'M2');
    }
    if (
      assetOut.label === 'WPEBPT' ||
      assetOut.label === 'YFUBPT' ||
      assetOut.label === 'PIXELBPT' ||
      assetOut.label === 'STRBPT' ||
      assetOut.label === 'M2BPT'
    ) {
      this._checkApprovalLiquidityBPT(
        realIn,
        assetOut,
        account,
        amountIn,
        (err) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          this._buyWPEBPTWithComboCall(
            assetOut,
            account,
            amountOut,
            amountIn,
            (err, res) => {
              if (err) {
                return emitter.emit(ERROR, err);
              }

              return emitter.emit(BUY_LP_RETURNED, res); //EXCHANGEETHFORTOKEN_RETURNED
            }
          );
        }
      );
    }
  };

  _buyWPEBPTWithComboCall = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const contractKey = asset.label + 'bptAddress';
    const contract = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config[contractKey]
    );

    console.info('Amount ', amount);
    console.info('value ', value);
    let multiplier = 1.005;
    const buyAmount = web3.utils.toWei(
      (+amount).toFixed(18).toString(),
      'ether'
    );

    let ethValue = await this._getOutputForWPEBPTwTokenEthVal(
      web3,
      value,
      account
    );
    if (asset.label === 'YFUBPT') {
      ethValue = await this._getOutputForYFUBPTwTokenEthVal(
        web3,
        value,
        account
      );
    } else if (asset.label === 'STRBPT') {
      ethValue = await this._getOutputForSTRBPTwTokenEthVal(
        web3,
        value,
        account
      );
    } else if (asset.label === 'PIXELBPT') {
      ethValue = await this._getOutputForPIXELBPTwTokenEthVal(
        web3,
        value,
        account
      );
    } else if (asset.label === 'M2BPT') {
      ethValue = await this._getOutputForM2BPTwTokenEthVal(
        web3,
        value,
        account
      );
    }
    const amountToSend = web3.utils.toWei(value, 'ether');
    // if (assetIn.decimals !== 18) {
    //   amountToSend = (value * 10 ** asset.decimals).toFixed(0);
    // }
    console.info('ETH VALUE ', ethValue);
    console.info(buyAmount);
    console.info(value);
    contract.methods
      .createLPWithTokens(amountToSend)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(
          (parseFloat(ethValue) * multiplier).toFixed(18).toString(),
          'ether'
        ),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        /*if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }*/
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  buyLPWithEth = (payload) => {
    const account = store.getStore('account');
    // assetIn
    const { assetOut, amountIn, amountOut } = payload.content;
    console.info(assetOut);
    if (assetOut.label === 'WPE' || assetOut.label === 'WBTC') {
      this._buyWPELPWithEthCall(
        assetOut,
        account,
        amountOut,
        amountIn,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(BUY_LP_RETURNED, res); //EXCHANGEETHFORTOKEN_RETURNED
        }
      );
    } else if (
      assetOut.label === 'WPEBPT' ||
      assetOut.label === 'YFUBPT' ||
      assetOut.label === 'PIXELBPT' ||
      assetOut.label === 'STRBPT' ||
      assetOut.label === 'M2BPT'
    ) {
      this._buyWPEBPTWithEthCall(
        assetOut,
        account,
        amountOut,
        amountIn,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(BUY_LP_RETURNED, res); //EXCHANGEETHFORTOKEN_RETURNED
        }
      );
    } else {
      this._buyLPWithEthCall(
        assetOut,
        account,
        amountOut,
        amountIn,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(BUY_LP_RETURNED, res); //EXCHANGEETHFORTOKEN_RETURNED
        }
      );
    }
  };

  _buyLPWithTokensEthCall = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    let contract = asset.label + 'bptAddress';
    const coinContract = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config[contract]
    );

    console.info('Amount ', amount);
    console.info('value ', value);
    let multiplier = 1.005;
    const buyAmount = web3.utils.toWei(
      (+amount).toFixed(18).toString(),
      'ether'
    );
    console.info(buyAmount);
    console.info(value);
    coinContract.methods
      .createLPETHToken()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(
          (parseFloat(value) * multiplier).toFixed(18).toString(),
          'ether'
        ),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        /*if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }*/
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  _buyWPEBPTWithEthCall = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const contractKey = asset.label + 'bptAddress';
    const contract = new web3.eth.Contract(
      config.WPEbptAddressABI,
      config[contractKey]
    );

    console.info('Amount ', amount);
    console.info('value ', value);
    const multiplier = 1.005;
    const buyAmount = web3.utils.toWei(
      (+amount).toFixed(18).toString(),
      'ether'
    );
    console.info(buyAmount);
    console.info(value);
    contract.methods
      .createLPETHToken()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(
          (parseFloat(value) * multiplier).toFixed(18).toString(),
          'ether'
        ),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        /*if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }*/
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  _buyWPELPWithEthCall = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    let contract = asset.label + 'lpAddress';
    const coinContract = new web3.eth.Contract(
      config.WPElpAddressABI,
      config[contract]
    );

    console.info('Amount ', amount);
    console.info('value ', value);
    let multiplier = 1.005;
    const buyAmount = web3.utils.toWei(
      (+amount).toFixed(18).toString(),
      'ether'
    );
    console.info(buyAmount);
    console.info(value);
    coinContract.methods
      .createLPETHToken()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(
          (parseFloat(value) * multiplier).toFixed(18).toString(),
          'ether'
        ),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        /*if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }*/
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  _buyLPWithEthCall = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    let contract = asset.label + 'lpAddress';
    const coinContract = new web3.eth.Contract(
      config.lpAddressABI,
      config[contract]
    );

    console.info('Amount ', amount);
    console.info('value ', value);
    let multiplier = 1.005;
    if (asset.label === 'PIXEL') {
      multiplier = 1.01;
    }
    const buyAmount = web3.utils.toWei(
      (+amount).toFixed(18).toString(),
      'ether'
    );

    console.info(buyAmount);
    console.info(value);
    console.info(multiplier);
    coinContract.methods
      .buyLPTokensEth(buyAmount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(
          (parseFloat(value) * multiplier).toFixed(18).toString(),
          'ether'
        ),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        /*if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }*/
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  buyLPWithToken = (payload) => {
    const account = store.getStore('account');
    const { assetIn, assetOut, amountIn, amountOut } = payload.content;
    this._checkApprovalLiquidity(
      assetIn,
      assetOut,
      account,
      amountIn,
      (err) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        this._buyLPWithTokenCall(
          assetOut,
          assetIn,
          account,
          amountOut,
          amountIn,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(BUY_LP_RETURNED, res);
          }
        );
      }
    );
  };

  _buyLPWithTokenCall = async (
    asset,
    token,
    account,
    amount,
    value,
    callback
  ) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    let contract = asset.label + 'lpAddress';
    const coinContract = new web3.eth.Contract(
      config.lpAddressABI,
      config[contract]
    );
    console.info('ASSET IN LP TOKEN CALL');
    console.info(asset);

    console.info(token);
    console.info('CONTRATO ', config[contract]);

    const buyAmount = web3.utils.toWei(amount.toString(), 'ether');
    // var amountToSend = web3.utils.toWei(amountIn, 'ether');
    // if (assetIn.decimals !== 18) {
    //   amountToSend = (amountIn * 10 ** assetIn.decimals).toFixed(0);
    // }
    coinContract.methods
      .buyLPTokensWithToken(buyAmount, token.address)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        /*if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }*/
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  buyLP = (payload) => {
    const { assetIn } = payload.content;
    if (assetIn.label === 'ETH') {
      //- [ ] BUYLPTOKENSWITHEYTHEREUM
      this.buyLPWithEth(payload);
    } else if (
      assetIn.label === 'WPE+ETH' ||
      assetIn.label === 'YFU+ETH' ||
      assetIn.label === 'PIXEL+ETH' ||
      assetIn.label === 'STR+ETH' ||
      assetIn.label === 'M2+ETH'
    ) {
      this.buyLPWithCombo(payload);
    } else {
      //BUYWITHTOKEN
      console.info('buy lp with token');
      this.buyLPWithToken(payload);
    }
  };

  /**
   * -------------------------
   * START STAKE ON BOOST
   * ----------------------------
   */
  boostStake = (payload) => {
    const account = store.getStore('account');
    const { asset, amount, value, beastModesAmount } = payload.content;
    // console.info('BOOOST!!!', asset);

    // return
    try {
      if (asset.isSuper) {
        if (+asset.selectedNftId === -2)
          return emitter.emit(ERROR, 'No NFT selected');
        this._boostcallStake2NFT(
          asset,
          account,
          beastModesAmount,
          value,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(STAKE_RETURNED, res);
          }
        );
      } else {
        if (asset.hiveId === 'wbtchive' || !asset.isHive) {
          console.info('hiiii', payload.content);
          this._boostcallStake2(
            asset,
            account,
            beastModesAmount,
            value,
            (err, res) => {
              if (err) {
                return emitter.emit(ERROR, err);
              }

              return emitter.emit(STAKE_RETURNED, res);
            }
          );
        } else {
          console.info('not hiiii', payload.content);
          this._boostcallStake(asset, account, amount, value, (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(STAKE_RETURNED, res);
          });
        }
      }
    } catch (error) {
      console.info(error);
      return emitter.emit(ERROR, error);
    }
  };

  _boostcallStake2NFT = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const boostContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    // console.info(asset.selectedNftId);
    // console.info(asset);

    boostContract.methods
      .bulkBeastMode(asset.selectedNftId, amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(`${value * 1.01}`, 'ether'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  _boostcallStake2 = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const boostContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    boostContract.methods
      .bulkBeastMode(amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(`${value * 1.01}`, 'ether'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  _boostcheckApproval = async (asset, account, contract, callback) => {
    try {
      const web3 = new Web3(store.getStore('web3context').library.provider);

      const erc20Contract = new web3.eth.Contract(
        config.erc20ABI,
        asset.boostTokenAddress
      );
      const allowance = await erc20Contract.methods
        .allowance(account.address, contract)
        .call({ from: account.address });

      const ethAllowance = web3.utils.fromWei(allowance, 'ether');

      if (parseFloat(ethAllowance) < parseFloat('999999999')) {
        await erc20Contract.methods
          .approve(contract, web3.utils.toWei('9999999999', 'ether'))
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
          });
        callback();
      } else {
        callback();
      }
    } catch (error) {
      // console.info(error);
      if (error.message) {
        return callback(error.message);
      }
      callback(error);
    }
  };

  _boostcallStakeNFT = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const boostContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    boostContract.methods
      .beastMode(asset.selectedNftId)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(value, 'ether'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  _boostcallStake = async (asset, account, amount, value, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const boostContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    boostContract.methods
      .beastMode()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        value: web3.utils.toWei(value, 'ether'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  /**
   * -------------------------
   * END STAKE ON BOOST
   * ----------------------------
   */
  stake = (payload) => {
    const account = store.getStore('account');
    const { asset, amount } = payload.content;
    console.info('stake asset.selectedNftId -----', asset.selectedNftId);
    console.info('stake asset.isSuper -----', asset.isSuper);

    this._checkApproval(asset, account, amount, asset.rewardsAddress, (err) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      if (asset.isSuper && asset.selectedNftId >= 0) {
        console.info('stake super ===========', asset.isSuper);

        this._callStakeSuper(asset, account, amount, (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }
          console.info(res);
          return emitter.emit(STAKE_RETURNED, res);
        });
      } else {
        this._callStake(asset, account, amount, (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }
          console.info(res);
          return emitter.emit(STAKE_RETURNED, res);
        });
      }
    });
  };

  _callStakeSuper = async (asset, account, amount, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    console.info('SUPER ASSSETR CALL STAKE');
    console.info(asset);
    console.info(asset.rewardsABI);
    const yCurveFiContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    var amountToSend = web3.utils.toWei(amount, 'ether');
    if (asset.decimals !== 18) {
      amountToSend = (amount * 10 ** asset.decimals).toFixed(0);
    }

    yCurveFiContract.methods
      .stakeExistingToken(asset.selectedNftId, amountToSend)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };
  _callStake = async (asset, account, amount, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    console.info('ASSSETR CALL STAKE');
    // console.info(asset);
    // console.info(asset.rewardsABI);
    const yCurveFiContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    const gasPrice = await this._getGasPrice();

    const amountToSend = web3.utils.toWei(amount, 'ether');
    if (asset.decimals !== 18) {
      amountToSend = (amount * 10 ** asset.decimals).toFixed(0);
    }

    yCurveFiContract.methods
      .stake(amountToSend)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(gasPrice, 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  withdraw = (payload) => {
    const account = store.getStore('account');
    const { asset, amount } = payload.content;

    this._callWithdraw(asset, account, amount, (err, res) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      return emitter.emit(WITHDRAW_RETURNED, res);
    });
  };

  _callWithdraw = async (asset, account, amount, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const yCurveFiContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    var amountToSend = web3.utils.toWei(amount, 'ether');
    if (asset.decimals !== 18) {
      amountToSend = (amount * 10 ** asset.decimals).toFixed(0);
    }

    yCurveFiContract.methods
      .withdraw(amountToSend)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  getReward = (payload) => {
    const account = store.getStore('account');
    const { asset } = payload.content;

    this._callGetReward(asset, account, (err, res) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      return emitter.emit(GET_REWARDS_RETURNED, res);
    });
  };

  _callGetReward = async (asset, account, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const yCurveFiContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    if (asset?.selectedNftId >= 0) {
      yCurveFiContract.methods
        .getReward(asset?.selectedNftId)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        })
        .on('transactionHash', function (hash) {
          // console.info(hash);
          callback(null, hash);
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          // console.info(confirmationNumber, receipt);
          if (confirmationNumber === 2) {
            dispatcher.dispatch({ type: GET_BALANCES, content: {} });
          }
        })
        .on('receipt', function (receipt) {
          // console.info(receipt);
        })
        .on('error', function (error) {
          if (!error.toString().includes('-32601')) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes('-32601')) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    } else {
      yCurveFiContract.methods
        .getReward()
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
        })
        .on('transactionHash', function (hash) {
          // console.info(hash);
          callback(null, hash);
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          // console.info(confirmationNumber, receipt);
          if (confirmationNumber === 2) {
            dispatcher.dispatch({ type: GET_BALANCES, content: {} });
          }
        })
        .on('receipt', function (receipt) {
          // console.info(receipt);
        })
        .on('error', function (error) {
          if (!error.toString().includes('-32601')) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes('-32601')) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    }
  };

  exit = (payload) => {
    const account = store.getStore('account');
    const { asset } = payload.content;

    this._callExit(asset, account, (err, res) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }
      return emitter.emit(EXIT_RETURNED, res);
    });
  };

  _callExit = async (asset, account, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const yCurveFiContract = new web3.eth.Contract(
      asset.rewardsABI,
      asset.rewardsAddress
    );

    var contract;
    if(asset.selectedNftId !== "-2" && asset.selectedNftId !== -2  && asset.selectedNftId !== null){
      contract = yCurveFiContract.methods
      .exit(asset.selectedNftId)
    }else{
      contract = yCurveFiContract.methods
      .exit()
    }

      contract
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_BALANCES, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  getClaimableAsset = (payload) => {
    const account = store.getStore('account');
    const asset = store.getStore('claimableAsset');

    const web3 = new Web3(store.getStore('web3context').library.provider);

    async.parallel(
      [
        (callbackInnerInner) => {
          this._getClaimableBalance(web3, asset, account, callbackInnerInner);
        },
        (callbackInnerInner) => {
          this._getClaimable(web3, asset, account, callbackInnerInner);
        },
      ],
      (err, data) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        asset.balance = data[0];
        asset.claimableBalance = data[1];

        store.setStore({ claimableAsset: asset });
        emitter.emit(GET_CLAIMABLE_ASSET_RETURNED);
      }
    );
  };

  _getClaimableBalance = async (web3, asset, account, callback) => {
    let erc20Contract = new web3.eth.Contract(asset.abi, asset.address);

    try {
      var balance = await erc20Contract.methods
        .balanceOf(account.address)
        .call({ from: account.address });
      balance = parseFloat(balance) / 10 ** asset.decimals;
      callback(null, parseFloat(balance));
    } catch (ex) {
      return callback(ex);
    }
  };

  _getClaimable = async (web3, asset, account, callback) => {
    let claimContract = new web3.eth.Contract(
      config.claimABI,
      config.claimAddress
    );

    try {
      var balance = await claimContract.methods
        .claimable(account.address)
        .call({ from: account.address });
      balance = parseFloat(balance) / 10 ** asset.decimals;
      callback(null, parseFloat(balance));
    } catch (ex) {
      return callback(ex);
    }
  };

  claim = (payload) => {
    const account = store.getStore('account');
    const asset = store.getStore('claimableAsset');
    const { amount } = payload.content;

    this._checkApproval(asset, account, amount, config.claimAddress, (err) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      this._callClaim(asset, account, amount, (err, res) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        return emitter.emit(CLAIM_RETURNED, res);
      });
    });
  };

  _callClaim = async (asset, account, amount, callback) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);

    const claimContract = new web3.eth.Contract(
      config.claimABI,
      config.claimAddress
    );

    var amountToSend = web3.utils.toWei(amount, 'ether');
    if (asset.decimals !== 18) {
      amountToSend = (amount * 10 ** asset.decimals).toFixed(0);
    }

    claimContract.methods
      .claim(amountToSend)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await this._getGasPrice(), 'gwei'),
      })
      .on('transactionHash', function (hash) {
        // console.info(hash);
        callback(null, hash);
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.info(confirmationNumber, receipt);
        if (confirmationNumber === 2) {
          dispatcher.dispatch({ type: GET_CLAIMABLE_ASSET, content: {} });
        }
      })
      .on('receipt', function (receipt) {
        // console.info(receipt);
      })
      .on('error', function (error) {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes('-32601')) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  };

  getClaimable = (payload) => {
    const account = store.getStore('account');
    const asset = store.getStore('claimableAsset');

    const web3 = new Web3(store.getStore('web3context').library.provider);

    async.parallel(
      [
        (callbackInnerInner) => {
          this._getClaimableBalance(web3, asset, account, callbackInnerInner);
        },
        (callbackInnerInner) => {
          this._getClaimable(web3, asset, account, callbackInnerInner);
        },
      ],
      (err, data) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        asset.balance = data[0];
        asset.claimableBalance = data[1];

        store.setStore({ claimableAsset: asset });
        emitter.emit(GET_CLAIMABLE_RETURNED);
      }
    );
  };

  _getGasPrice = async () => {
    try {
      const ethMemo = new MemoFetch(
        'https://blockscout.com/eth/mainnet/api/v1/gas-price-oracle'
      );
      const prices = await ethMemo.json();
      return prices.fast.toFixed(0) || store.getStore('universalGasPrice');
    } catch (e) {
      return store.getStore('universalGasPrice');
    }
  };

  /**
   *
   * @param {String} user - address
   * @param {Number} amount - uint256
   * @returns
   */
  getBoosterPriceBulk = async (asset, amount) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    const account = store.getStore('account');

    // console.info(asset.rewardsAddress, account.address, amount);
    // console.info(asset);

    try {
      const boostContract = new web3.eth.Contract(
        asset.rewardsABI,
        asset.rewardsAddress
      );
      if (asset.isSuper && asset?.selectedNftId < 0) {
        return {
          boosterPrice: 0,
          newBoostBalance: 0,
        };
      }
      const id = !!(asset?.selectedNftId?.length && +asset?.selectedNftId >= 0)
        ? asset.selectedNftId
        : account.address;
      console.info('id ----', id);
      let results = await boostContract.methods
        .getBoosterPriceBulk(id, +amount)
        .call({ from: account.address });

      return {
        boosterPrice: results.boosterPrice / 10 ** 18,
        newBoostBalance: results.newBoostBalance / 10 ** 18,
      };
    } catch (error) {
      console.info(error);
    }

    // return {
    //   boosterPrice,
    //   newBoostBalance,
    // };
  };

  getTotalSupply = async (asset) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    const account = store.getStore('account');

    try {
      const boostContract = new web3.eth.Contract(
        asset.abi,
        asset.rewardsAddress
      );

      // console.info(asset.name, asset.rewardsAddress);

      let results = await boostContract.methods
        .totalSupply()
        .call({ from: account.address });
      return results / 10 ** 18;
    } catch (error) {
      console.info(error);
    }
  };

  /**
   * Function that returns the total lock volume of a pool.
   * @param {String} lpLabel label of the lpToken which will be used to get price.
   * @param {Token} pool token which will be used to get Total Supply.
   * @returns {Number} value of the total lock volume.
   */

  getTotalLockVolume = async (asset) => {
    const rewardPool = this.getStore('rewardPools').find(
      (pool) => pool.tokens[0].address === asset.address
    );
    if (!rewardPool) return;
    const lpToken = store
      .getStore('lpTokens')
      .find((token) => token.hiveId === rewardPool.id);
    if (!lpToken || !lpToken?.price) return;

    console.info(
      rewardPool.id,
      lpToken.hiveId,
      asset.totalSupply,
      lpToken.price
    );

    // const supplyToken = this.getStore('rewardPools')
    //   .map((x) => x.tokens)
    //   .flat()
    //   .find(
    //     ({ address, tokenAddress }) =>
    //       asset.address === address || asset.address === tokenAddress
    //   );

    const totalSupply = asset.totalSupply;
    return totalSupply * lpToken.price;
  };

  getLockTime = async (address) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    const account = store.getStore('account');
    try {
      const contract = new web3.eth.Contract(
        config.seedzABI,
        config.seedzAddress
      );
      let lockTime = await contract.methods
        .lockTime(address)
        .call({ from: account.address });
      console.info(lockTime);
      return lockTime;
      // lockTime siempre son iguales a 0
    } catch (error) {
      console.info(error);
    }
  };

  getETHPrice = async (callback) => {
    const ethMemo = new MemoFetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD',
      10
    );
    const res = await ethMemo.json();
    return parseFloat(res.ethereum.usd);
  };

  // super hive functions -----------------------------------

  /**
   * @returns return the ids available in your wallet
   */
  walletNftQty = async (nftAddress) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    const account = store.getStore('account');
    let ierc721Contract = new web3.eth.Contract(
      config.ierC721ENUMERABLE,
      nftAddress
    );

    try {
      var nftQty = await ierc721Contract.methods
        .balanceOf(account.address)
        .call({ from: account.address });
      return nftQty;
    } catch (error) {
      console.info(error);
      throw error;
    }
  };

  /**
   * @param {index} index
   * if no index is provided, it will crash.
   */
  tokenOfOwnerByIndex = async (index, nftAddress) => {
    const web3 = new Web3(store.getStore('web3context').library.provider);
    const account = store.getStore('account');
    let contract = new web3.eth.Contract(config.ierC721ENUMERABLE, nftAddress);

    try {
      var tokenofowner = await contract.methods
        .tokenOfOwnerByIndex(account.address, index)
        .call({ from: account.address });

      // console.info('tokenofowner', tokenofowner);
      return tokenofowner;
    } catch (error) {
      console.info(error);
      throw error;
    }
  };

  getNFTIds = async (nftAddress) => {
    const nftIdsResult = [];
    const walletNftQty = await this.walletNftQty(nftAddress);

    if (!+walletNftQty) return null;

    for (let i = 0; i < walletNftQty; i++) {
      nftIdsResult.push(await this.tokenOfOwnerByIndex(i, nftAddress));
    }

    return nftIdsResult;
  };

  saveNFTId = (pool, nftId) => {
    const account = store.getStore('account');
    if (!account) return;
    // console.info('account', account);
    // console.info('account', account.address + '/' + pool.address + '/nftId');
    localStorage.setItem(
      account.address + '/' + pool.address + '/nftId',
      nftId.toString()
    );
    // const pools = [...store.getStore('rewardPools')];
    // const currentPool = pools.find(({ id }) => pool.id === id);
    // if (currentPool) {
    //   currentPool.tokens[0].selectedNftId = nftId;
    // }
    // let p = { ...pool };
    // p.token.selectedNftId = nftId;
    // store.setStore({ rewardPools: pools });
    emitter.emit(CONFIGURE_RETURNED);
    // dispatcher.dispatch({ type: GET_BALANCES, content: {} });
  };

  /**
   * Return an Object with the nftIds and the selectedToken of a given token in
   * pool.
   * @param {Pool} pool Pool that will be queried.
   * @param {Number} ix Index of the token that will be queried. Default to 0.
   * @returns {Object}
   */
  tokenNFTs = async (pool, ix = 0) => {
    const account = store.getStore('account');
    const selectedId = localStorage.getItem(
      `${account.address}/${pool.tokens[0].rewardsAddress}/nftId`
    );
    // console.info(selectedId);
    // console.info(!!selectedId);
    // console.info(pool);
    // console.info(`${account.address}/${pool.tokens[0].rewardsAddress}/nftId`);
    const nftIds = (await this.getNFTIds(pool.tokens[ix].stakeNFT)) || [];
    // console.info(nftIds);
    // nftIds.push('');
    const res = {
      nftIds,
      selectedNftId: selectedId,
      // nftIds.length > 0 ? (!!selectedId ? selectedId : nftIds[0]) : -1,
    };
    // console.info(res);
    return res;
  };

  /**
   * Returns the staked amount of a given token.
   * @param {Token} token
   * @returns {Number} stakedAmount
   */
  getStakedAmountUsd = async (token) => {
    // console.info('  ', token);
    // const eth = store.getStore('poolInTokens').find((i) => i.label === 'ETH');
    try {
      const assetOut = store
        .getStore('exchangeAssets')
        .tokens.find(
          ({ liquidityPoolAddress }) => token.address === liquidityPoolAddress
        );

      if (assetOut && token.stakedBalance) {
        const lpToken = store
          .getStore('lpTokens')
          .find((i) => i.address === assetOut.address);

        let stakedAmountUsd = token.stakedBalance * lpToken.price;

        return +stakedAmountUsd;
      }
      return 0;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Adds seeds to a given pool.
   * @param {Pool} pool Seeds will be added to this pool
   * @returns
   */
  addSeeds = async (pool) =>
    new Promise((res, rej) => {
      let provider = new Web3(store.getStore('web3context').library.provider);
      provider = provider.currentProvider;
      provider.sendAsync(
        {
          method: 'metamask_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: pool.tokenAddress,
              symbol: pool.tokenSymbol,
              decimals: 18,
              image: '',
            },
          },
          id: Math.round(Math.random() * 100000),
        },
        (err, added) => {
          if (err || 'error' in added) {
            emitter.emit(ERROR, 'There was a problem adding the token.');
            return rej(err);
          }
          return res(added);
        }
      );
    });

  notifyRewardAmount = async (token, rewardAmount) => {
    console.info(token.symbol, rewardAmount);

    const web3 = new Web3(store.getStore('web3context').library.provider);
    const account = store.getStore('account');
    try {
      const contract = new web3.eth.Contract(
        token.rewardsABI,
        token.rewardsAddress
      );
      // no response as it does not return anything
      await contract.methods
        .notifyRewardAmount(rewardAmount)
        .send({ from: account.address })
        .catch((error) => {
          console.info(error);
          emitter.emit(ERROR, error);
        });

      // lockTime siempre son iguales a 0
    } catch (error) {
      console.info(error);
    }
  };
}

var store = new Store();

export default {
  store,
  dispatcher,
  emitter,
};
