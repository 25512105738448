import abis from './abis.config';

export default {
  infuraProvider:
    'https://mainnet.infura.io/v3/b111d8f387c847039541e29435e06cd2',

  yCurveFiRewardsAddress: '0x98C51906264ef99B7cF02a60c6cCC81C898C5fD4',

  // TODO
  pixelHiveAddress: '0x9f8A64574F73DC71E6FEb2300183aD6B4404C867',
  yfuHiveAddress: '0xc96d43006fE0058c5dd9d35D2763Aba9A0C300b1',
  liftHiveAddress: '0x558E4189d0A206Eb6fb7451B0A5263f75A13A3a3',
  strHiveAddress: '0xE1C0E3c6B0cbA894932a5599e91cCf63E23C1693',
  wpeHiveAddress: '0xf7233E9816C4c08a9B83Ffc944701854d3CEf6b4',
  wbtcHiveAddress: '0x9411aE40e4EefE2BDCF6F4e2beC81BEb7682bC63',
  wpeSuperHiveAddress: '0xD4c8D5de63ec1C808576D49842b22aeA6e389eF5',
  yfuSuperHiveAddress: '0x45729F8333E0Ad91811a10DE1F0D1B5b72ed3dE8',
  pixelSuperHiveAddress: '0x69dD1ef819Afde481d81E6C1C1Bb91CabCFe780f',
  strSuperHiveAddress: '0xDA58489Bd1dA080ca3E3Af77263457decfe1128c',
  m2SuperHiveAddress: '0x63CBFF2c67a21dc256ba28Fc602d324b2e7e55F3',
  // CHANGE THIS!!!
  farmpixel: '0x1089c0132423CeBE0A5b03125B3D7f9f31c538EB',
  farmyfu: '0xAFc076D8a22D6698A8C7F2B591326DF418340f28',
  // farmlift: '0x9f8A64574F73DC71E6FEb2300183aD6B4404C867-LIFT',
  farmstr: '0x88121B6D8aED2afafE37D844D325a1579209e362',

  farmsRewardAddress: '0xa279dab6ec190eE4Efce7Da72896EB58AD533262',
  // farmsRewardsAbi: [],
  //Converter addresses
  YFUlpAddress: '0xb4DA88fA5292d6d7c9Bb9E20255Dd6596A9b93E0',
  LIFTlpAddress: '0x8a04e2991496CbC122E3f5d00289246e4e8ab7A9',
  STRlpAddress: '0x5d35a56d67a7659e252Bb2b5FAa942191911c9c1',
  PIXELlpAddress: '0xD20B4a71d1F59E37713aba361A0a25a64a7D335D',
  WBTClpAddress: '0xe9762E1b97bAdB0fdC95c7A6D229a93Ab87Fe3fD',
  WPEBPTbptAddress: '0xA0cEB04D3b3c9EBFa11f31C65887b5413D066C30',
  YFUBPTbptAddress: '0x485DcbA4594f54ae23478bb32aA411012e33D62a',
  PIXELBPTbptAddress: '0x71CE770bBca639BbBB1A0E7FE455b118d80bA173',
  STRBPTbptAddress: '0x00FE1Ad7b413bf84a45D37B687243Fe8A848bccF',
  M2BPTbptAddress: '0x4ceB6f37b4D378283EB4855fB54d518a1A414435',

  WPEBPT721Address: '0xD8F16fc27901f77d58A41101B096b47b30C052d2',
  IERC721Address: '0xc16d9049e251b872f269c09c3e9eb56c6d035f5f',
  ...abis,
};
