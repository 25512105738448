import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
// import config from '../../config/config';
import Store from '../../stores/store';
const { store } = Store;

const Rewards = () => {
  const [selectVal, setSelectVal] = useState('rewardPools');
  const [pools, setPools] = useState(store.getStore('rewardPools'));
  // const [account, setAccount] = useState(store.getStore('account'));
  // const [themeType, setThemeType] = useState(store.getStore('themeType'));

  const submitAll = async (e) => {
    e.preventDefault();
    console.info('do all');
  };

  const notifyRewardAmount = async (pool) => {
    try {
      console.info(pool.rewardsToNotify);
      await store.notifyRewardAmount(pool.tokens[0], pool.rewardsToNotify);
    } catch (error) {
      console.info(error);
    }
  };

  const onChangeSelect = (e) => {
    const selectedOption = e.target.value;
    console.info(selectedOption);
    setSelectVal(selectedOption);
    setPools(store.getStore(selectedOption));
  };

  return (
    <>
      <div className='pageHeader my-auto'>Dashboard</div>
      <div className='ml-sm-5 px-sm-5 ml-5 px-1 pb-5'>
        <div className='section-title text-left mt-4 mb-2 p-0'>
          You shouldn't be here
        </div>

        <div>
          <select
            className='form-select'
            name=''
            id=''
            value={selectVal}
            onChange={onChangeSelect}
          >
            <option value='farmPools'>farms</option>
            <option value='rewardPools'>pools</option>
          </select>
          <hr />
        </div>

        <form onSubmit={submitAll}>
          {pools.map((pool, i) => {
            const token = pool.tokens[0];
            return (
              <div className='row mb-3' key={pool.id}>
                <div className='col-md-6'>
                  hive id: {pool.id}
                  <br />
                  <small>
                    <span>rewards address:</span>
                    {token.rewardsAddress}
                  </small>
                </div>

                <div className='col-md-3'>
                  <input
                    type='number'
                    min='0'
                    onChange={(e) => {
                      console.info(e.target.value);
                      pool.rewardsToNotify = e.target.value;
                    }}
                    placeholder='reward'
                    name=''
                    id=''
                  />
                </div>
                <div className='col-md-3'>
                  <button
                    className='btn btn-sm btn-primary'
                    type='button'
                    onClick={() => {
                      notifyRewardAmount(pool);
                    }}
                  >
                    notifyRewardAmount
                  </button>
                </div>
              </div>
            );
          })}
          {/* <button type='submit'>do all</button> */}
        </form>
      </div>
    </>
  );
};

export default withNamespaces()(withRouter(Rewards));
