import { erc20ABI } from '../../config/config';
import { MemoFetch } from './utils';

// get balances: =============================================================
// _getERC20Balance
// _getstakedBalance
// _getRewardsAvailable
// _getUniswapLiquidity
// _getBalancerLiquidity
// _getRatePerWeek
// _getBonusAvailable

export const _getERC20Balance = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(erc20ABI, asset.address);

  try {
    let balance = await contract.methods
      .balanceOf(account.address)
      .call({ from: account.address });
    balance = parseFloat(balance) / 10 ** asset.decimals;

    return parseFloat(balance);
  } catch (error) {
    console.info(error, asset);
    throw error;
  }
};

export const _getstakedBalance = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );
  // console.info('balance -----', balance, asset.name);
  const id = !!(asset?.selectedNftId?.length && +asset?.selectedNftId >= 0)
    ? asset.selectedNftId
    : account.address;

  try {
    let balance = await contract.methods
      .balanceOf(id)
      .call({ from: account.address });

    balance = parseFloat(balance) / 10 ** asset.decimals;

    // console.info('_stakedbalance asset ---', asset, id, balance);
    // console.info('_stakedbalance', parseFloat(balance));

    return parseFloat(balance);
  } catch (error) {
    console.info(error);
    throw error;
  }
};

export const _getRewardsAvailable = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );

  const id = !!(asset?.selectedNftId?.length && +asset?.selectedNftId >= 0)
    ? asset.selectedNftId
    : account.address;

  // if is superhive and id == address, we dont want this to be invoked
  if (asset.isSuper && id.charAt(1) === 'x') return 0;
  // console.info('id ====', id);
  try {
    let earned = await contract.methods
      .earned(id)
      .call({ from: account.address });
    earned = parseFloat(earned) / 10 ** asset.decimals;

    return parseFloat(earned);
  } catch (error) {
    console.info('id ====', id);
    console.info(error);
    throw error;
  }
};

export const _getUniswapLiquidity = async (callback) => {
  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const graphql = JSON.stringify({
      query:
        '{\n pair(id: "0x75f89ffbe5c25161cbc7e97c988c9f391eaefaf9"){\n     reserveUSD\n}\n}',
      variables: {},
    });
    const requestOptions = {
      method: 'POST',
      headers,
      body: graphql,
      redirect: 'follow',
    };

    const ethMemo = new MemoFetch('https://api.thegraph.com/subgraphs/name/ianlapham/uniswapv2');
    const myJson = await ethMemo.json(requestOptions);
    // console.info(myJson.data.pair.reserveUSD);

    const res = parseFloat(myJson.data.pair.reserveUSD).toFixed(2);

    return res;
  } catch (ex) {
    throw ex;
  }
};

export const _getBalancerLiquidity = async (callback) => {
  try {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    var graphql = JSON.stringify({
      query:
        '{\n pool(id: "0x5b2dc8c02728e8fb6aea03a622c3849875a48801"){\n     liquidity\n}\n}',
      variables: {},
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow',
    };

    const ethMemo = new MemoFetch('https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-beta');
    const myJson = await ethMemo.json(requestOptions);

    // console.info(myJson.data.pool.liquidity);
    const res = parseFloat(myJson.data.pool.liquidity).toFixed(2);

    return res;
  } catch (ex) {
    console.info(ex);
    throw ex;
  }
};

export const _getRatePerWeek = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );

  try {
    let rate = await contract.methods
      .currentReward()
      .call({ from: account.address });
    rate = parseFloat(rate) / 10 ** asset.decimals;
    return rate;
  } catch (error) {
    console.info(error);
    throw error;
  }
};

export const _getBonusAvailable = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );

  try {
    let beastmodes = await contract.methods
      .timeLockLevel()
      .call({ from: account.address });
    beastmodes = parseFloat(beastmodes[1]);

    return beastmodes;
  } catch (error) {
    console.info(error);
    throw error;
  }
};

// boost balances: ===========================================================
// _getBoosters
// _getBoosterCost
// _getBoostTokenBalance
// _getboostedBalances
// _getBoosterPrice
// _getNextBoostTime
// _getETHPrice

export const _getBoosters = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );

  const id = !!(asset?.selectedNftId?.length && +asset?.selectedNftId >= 0)
    ? asset.selectedNftId
    : account.address;

  try {
    let balance = await contract.methods
      .numBoostersBought(id)
      .call({ from: account.address });
    balance = parseFloat(balance);

    return balance;
  } catch (error) {
    console.info(asset, id, error);
    console.info(id);
    console.info(error);
    throw error;
  }
};

export const _getBoosterCost = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );

  const id = !!(asset?.selectedNftId?.length && +asset?.selectedNftId >= 0)
    ? asset.selectedNftId
    : account.address;

  // if is superhive and id == address, we dont want this to be invoked
  if (asset.isSuper && id.charAt(1) === 'x') return [0, 0];

  try {
    const balance = await contract.methods
      .getBoosterPrice(id)
      .call({ from: account.address });
    // console.info(balance);
    // console.info(balance[0]);
    // console.info(balance[1]);

    const boostInfo = [
      parseFloat(balance[0]) / 10 ** asset.decimals,
      parseFloat(balance[1]) / 10 ** asset.decimals,
    ];

    return boostInfo;
  } catch (error) {
    console.info(error);
    throw error;
  }
};

export const _getBoostTokenBalance = async (web3, asset, account, callback) => {
  try {
    let balance = await web3.eth.getBalance(account.address);

    let boostInfo = parseFloat(balance) / 10 ** asset.decimals;

    return boostInfo;
  } catch (ex) {
    console.info(ex);
    throw ex;
  }
};

export const _getboostedBalances = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );
  const id = !!(asset?.selectedNftId?.length && +asset?.selectedNftId >= 0)
    ? asset.selectedNftId
    : account.address;
  try {
    const balance = await contract.methods
      .boostedBalances(id)
      .call({ from: account.address });
    const boostInfo = parseFloat(balance) / 10 ** asset.decimals;
    return boostInfo;
  } catch (error) {
    console.info(error);
    throw error;
  }
};

export const _getBoosterPrice = async () => {
  const ethMemo = new MemoFetch(
    'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD'
  );
  try {
    const res = await ethMemo.json();
    const balance = parseFloat(res.ethereum.usd);
    return balance;
  } catch (error) {
    console.info(error);
    throw error;
  }
};

export const _getNextBoostTime = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );
  // console.info('>>>>>>> NEXT BOOST TIME');
  const id = !!(asset?.selectedNftId?.length && +asset?.selectedNftId >= 0)
    ? asset.selectedNftId
    : account.address;
  try {
    const time = await contract.methods
      .nextBoostPurchaseTime(id)
      .call({ from: account.address });
    const boostInfo = parseInt(time);
    return boostInfo;
  } catch (error) {
    console.info(error);
    throw error;
  }
};

export const _boostedTotalSupply = async (web3, asset, account) => {
  const contract = new web3.eth.Contract(
    asset.rewardsABI,
    asset.rewardsAddress
  );
  try {
    const result = await contract.methods
      .boostedTotalSupply()
      .call({ from: account.address });

    return result / 10 ** 18;
  } catch (error) {
    console.info(error);
    throw error;
  }
};

export const _getETHPrice = async () => {
  const ethMemo = new MemoFetch(
    'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD'
  );
  try {
    const myJson = await ethMemo.json();
    var balance = parseFloat(myJson.ethereum.usd);
    return parseFloat(balance);
  } catch (ex) {
    throw ex;
  }
};
