import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
// import config from '../../config/config';
import Web3 from 'web3';
import Store from '../../stores/store';
const { store } = Store;

const Boosted = (props) => {
  const [selectVal, setSelectVal] = useState('rewardPools');
  const [pools, setPools] = useState(null);
  const [lpTokens, setLpTokens] = useState(store.getStore('lpTokens'));
  // const [account, setAccount] = useState(store.getStore('account'));
  // const [themeType, setThemeType] = useState(store.getStore('themeType'));

  const getBTPIndexPrice = async () => {
    const assetOut = store
      .getStore('exchangeAssets')
      .tokens.find((i) => i.label === 'USDC'); //USDC

    const assetsIn = store
      .getStore('exchangeAssets')
      .tokens //.filter((a) => a.box);

    const assetIn = assetsIn.find((asset) => asset.label === 'WPE');
    let res = await store.getAmountOut(assetIn, assetOut, 1);
    console.log(assetIn);
    console.log(res);
    
    // const promises = assetsOut.map((assetOut) =>
    //   store.getPrice(assetIn, assetOut)
    // );
    // const results = await Promise.all(promises);

    // for (const assetLabel of ['ETH', 'WPE']) {
    //   const current = store
    //     .getStore('exchangeAssets')
    //     .tokens.find((i) => i.label === assetLabel);

    //   console.log(current);
    // }
  };

  useEffect(() => {
    const filtered = store
      .getStore('rewardPools')
      .map((p) => {
        const lpToken = store
          .getStore('lpTokens')
          .find(({ hiveId }) => hiveId == p.id);
        p.lpToken = lpToken ? lpToken : null;
        return p;
      })
      .filter((p) => !!p.lpToken);

    getBTPIndexPrice();

    setPools(filtered);
  }, [props.id]);

  const notifyRewardAmount = async (pool) => {
    try {
      console.info(pool.rewardsToNotify);
      await store.notifyRewardAmount(pool.tokens[0], pool.rewardsToNotify);
    } catch (error) {
      console.info(error);
    }
  };

  const onChangeSelect = (e) => {
    const selectedOption = e.target.value;
    console.info(selectedOption);
    setSelectVal(selectedOption);
    setPools(store.getStore(selectedOption));
  };

  return (
    <>
      <div className='pageHeader my-auto'>Dashboard</div>
      <div className='ml-sm-5 px-sm-5 ml-5 px-1 pb-5'>
        <hr />
        {pools &&
          pools.map((pool, i) => {
            const token = pool.tokens[0];
            return (
              <div className='row mb-4' key={pool.id}>
                <div className='col-md-3'>
                  hive id: {pool.id} | lp token label: {pool.lpToken.label}
                  <br />
                  {/* <small>
                    <span>rewardAddress:</span>
                    {token.rewardsAddress}
                  </small> */}
                </div>
                <div className='col-md-3'>
                  <div>lp price</div> <small>{pool.lpToken.price}</small>
                </div>
                <div className='col-md-3'>
                  <div>boostedTotalSupply</div>{' '}
                  <small>{token.boostedTotalSupply}</small>
                </div>
                <div className='col-md-3'>
                  <div>Result</div>{' '}
                  <small>{(token.boostedTotalSupply * pool.lpToken.price).toLocaleString()}</small>
                  <div>Result / 52</div>{' '}
                  <small>
                    {((token.boostedTotalSupply * pool.lpToken.price) / 52).toLocaleString()}
                  </small>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default withNamespaces()(withRouter(Boosted));
