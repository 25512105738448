import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function GasPrice(props) {
  const [gasPrice, setGasPrice] = useState(10);

  async function getGasPrice(source) {
    try {
      let { data } = await axios.get(
        'https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=FY4D4P9IFB1FB3AGA5945WHG4VAQNVPMGD'
      );
      
      setGasPrice(data.result.FastGasPrice);
    } catch (error) {
      console.info(error);
    }
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    getGasPrice(source);
    let interval = setInterval(() => {
      getGasPrice(source);
    }, 60000);
    return () => {
      source.cancel('');
      clearInterval(interval);
    };
  }, [props.id]);

  return (
    <span>
      <img
        alt=''
        className='mr-2'
        src={require('../../assets/fuel.png')}
        height='20'
      />
      {gasPrice / 10} gwei
    </span>
  );
}
